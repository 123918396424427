import React, { useEffect } from 'react';
// @ts-ignore
import Button from '@atlaskit/button';
import { AtlasLink, track } from '../atlas-link';
import './contact-list-item.less';
import { translatableText } from '../../utils/translatable-text';
import { useSupportFormUrls } from '../../hooks/use-support-form-urls';
import { useAuth } from '../../providers/auth-providers';
import analytics from '../../utils/analytics';
import { get } from 'lodash';

interface ContactProps {
    supportFormUrls?: SupportFormUrls;
}

export interface SupportFormUrls {
    authenticated: string | null;
    unauthenticated: string | null;
}

export const ContactCtaBlock = ({ supportFormUrls }: ContactProps) => {
    const supportFormLinks = useSupportFormUrls(supportFormUrls);
    const { isAuthenticated } = useAuth();

    const { href: supportFormHref, text: supportFormText } =
        supportFormLinks[
            isAuthenticated ? 'authenticated' : 'needsAuthentication'
        ];
    return (
        <div className="contact-block">
            <div className="contact-block--inner">
                <h2 className="margin-top-large contact-block__header">
                    {translatableText.notFindingTheHelpYouNeed.text}
                </h2>
                <Button
                    ariaLabel="contact support"
                    className="contact-block--btn margin-top-large"
                    onClick={() =>
                        track(
                            supportFormHref,
                            'Contact Technical Support',
                            'contactTechnicalSupportButton'
                        )
                    }
                    href={supportFormHref}
                >
                    {supportFormText}
                </Button>
                {supportFormUrls && supportFormUrls.unauthenticated && (
                    <div className="margin-top-medium">
                        <Button
                            className='margin-top-medium"'
                            appearance="link"
                            href={supportFormUrls.unauthenticated}
                            onClick={() =>
                                track(
                                    supportFormUrls?.unauthenticated,
                                    'Contact Support Without Logging In',
                                    'contactSupportWithoutLoggingIn'
                                )
                            }
                        >
                            {
                                translatableText.supportRequestWithoutLoggingIn
                                    .text
                            }
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export const ContactListItem = ({ supportFormUrls }: ContactProps) => {
    const supportFormLinks = useSupportFormUrls(supportFormUrls);
    const { isAuthenticated } = useAuth();

    const { href: supportFormHref } =
        supportFormLinks[
            isAuthenticated ? 'authenticated' : 'needsAuthentication'
        ];

    useEffect(() => {
        if (isAuthenticated) {
            analytics.trackEvent('goToContactFormButtonLoaded', {
                // @ts-ignore
                event: 'loaded',
                eventComponent: 'goToContactFormButtonLoaded',
                action: 'loaded',
                contentful_entry_id: get(
                    window,
                    '__APP_INITIAL_STATE__.entry.id',
                    null
                )
            });
        }
    }, [isAuthenticated]);
    return (
        <AtlasLink
            dataName="contactButton"
            href={supportFormHref}
            text={translatableText.contact.text}
        />
    );
};
