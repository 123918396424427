import React from 'react';
import Tabs from '@atlaskit/tabs';
import { ProductCardsGroup } from '../product-card';
import { Context } from 'src/content';
import './deployment-selector-tabs.less';
import analytics from '../../utils/analytics/analytics';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';

const handleTabSelect = (details: string, tab: string) => {
    const tabName = tab === 'Data Center' ? 'Server' : tab;
    analytics.trackEvent(camelCase(tabName + ' tab'), {
        //@ts-ignore
        event: 'clicked',
        eventComponent: 'tab',
        category: 'products cloud and server tabs',
        actionSubjectId: camelCase(details + ' products ' + tabName + ' tab'),
        action: 'click',
        label: tab,
        contentful_entry_id: get(window, '__APP_INITIAL_STATE__.entry.id', null)
    });
};

export const DeploymentSelectorTabs = ({
    serverProducts,
    cloudProducts,
    details
}: {
    serverProducts: Context[];
    cloudProducts: Context[];
    details: string;
}) => {
    return (
        <Tabs
            tabs={[
                {
                    label: 'Cloud',
                    content: <ProductCardsGroup productList={cloudProducts} />
                },
                {
                    label: 'Data Center',
                    content: <ProductCardsGroup productList={serverProducts} />
                }
            ]}
            onSelect={(selected) => {
                selected.label && handleTabSelect(details, selected.label);
            }}
        />
    );
};
