import React, { useMemo } from 'react';
import Bug from '@atlaskit/icon-object/glyph/bug/16';
import Story16Icon from '@atlaskit/icon-object/glyph/story/16';
import {
    highlightSearchTerm,
    highlightText
} from '../../utils/highlight-search-result-keyword';

export enum TicketType {
    BUG = 'bug',
    SUGGESTION = 'suggestion'
}

interface TitleSectionProps {
    ticketType?: TicketType;
    title: string;
    searchTerm?: string; // TODO: Remove when isSearchTermHighlightingEnabled is fully enabled
    isSearchTermHighlightingEnabled?: boolean;
}

export const TitleSection: React.FC<TitleSectionProps> = ({
    ticketType,
    title,
    searchTerm = '', // TODO: Remove when isSearchTermHighlightingEnabled is fully enabled
    isSearchTermHighlightingEnabled
}: TitleSectionProps) => {
    const StoryType = useMemo(() => {
        if (ticketType === TicketType.BUG) {
            return <Bug label="Bug" />;
        } else if (ticketType === TicketType.SUGGESTION) {
            return <Story16Icon label="Suggestion" />;
        }
    }, [ticketType]);

    return (
        <div className="title-section">
            {ticketType !== undefined && (
                <div className="card-type" data-testid="ticket-type">
                    {StoryType}
                    <span className="label font-weight-400">
                        {ticketType === TicketType.BUG ? 'Bug' : 'Suggestion'}
                    </span>
                </div>
            )}
            <h3
                className="card-title font-weight-600 text-gray"
                data-testid="title-section__title"
            >
                {isSearchTermHighlightingEnabled
                    ? highlightText(title)
                    : highlightSearchTerm(searchTerm, title)}
            </h3>
        </div>
    );
};
