import React, { Component } from 'react';
import { LOGIN_PROMPT_TYPES, MANAGE_ACCOUNT_LINK } from '../../utils/constants';
import { getLoginUrl, getLogoutUrl } from '../../utils/get-auth-url';
import { getMyRequestsLink } from '../../utils/get-my-requests-link';
import { getNamedContactsLink } from '../../utils/get-named-contacts-link';
import { AtlasLink } from '../atlas-link';
import { translatableText } from '../../utils/translatable-text';
import { isAccountSwitcherEnabled } from '../../../feature-flags';
import Heartbeat from '@atlassian/heartbeat';

import {
    addCurrentEvnToDomain,
    wrapUrlWithLogin
} from '../../../common/url-rules';
import { MENU_ITEM_TYPES } from './account-menu-constants';
import Popup from '@atlaskit/popup';

// @ts-ignore
import Avatar from '@atlaskit/avatar';
// @ts-ignore
import AccountMenuDialog from './components/account-menu-dialog';
import {
    WithAuthDetails,
    AuthDetailsInterface
} from '../../providers/auth-providers';

interface AccountMenuProps {
    showPartnerRequestItems: boolean;
    isMobile: boolean;
    authDetails: AuthDetailsInterface;
}

class AccountMenu extends Component<AccountMenuProps> {
    constructor(props: AccountMenuProps) {
        super(props);
        this.state = {
            accountMenuDropdownOpen: false,
            logoutLink: '',
            loginLink: '',
            accountSwitcherEnabled: false
        };
    }

    async componentDidMount() {
        const loginLink = getLoginUrl(window.location.href);
        const logoutLink = getLogoutUrl();

        const accountSwitcherEnabled = await isAccountSwitcherEnabled();

        this.setState({
            loginLink,
            logoutLink,
            accountSwitcherEnabled
        });
    }

    showAccountMenu(newState: boolean) {
        this.setState({
            accountMenuDropdownOpen: newState
        });
    }

    async redirectToLoginPage() {
        // @ts-ignore
        await fetch(this.state.loginLink, { mode: 'no-cors' });
    }

    renderHeartbeat() {
        const endpoint = window.location.hostname.includes('partners')
            ? `${window.location.protocol}//${window.location.hostname}/gateway/api/session/heartbeat`
            : '/gateway/api/session/heartbeat';
        return (
            <Heartbeat
                endpoint={endpoint}
                onAuthenticationFailed={this.redirectToLoginPage}
            />
        );
    }

    getSwitchAccountURL = () => {
        return wrapUrlWithLogin({
            url: window.location.href,
            host: window.location.host,
            prompt: LOGIN_PROMPT_TYPES.SELECT_ACCOUNT
        });
    };

    getViewAccountURL = () => {
        return wrapUrlWithLogin({
            url: addCurrentEvnToDomain(
                MANAGE_ACCOUNT_LINK,
                window.location.host
            ),
            host: window.location.host,
            // @ts-ignore
            email: this.state.userEmail,
            prompt: LOGIN_PROMPT_TYPES.NONE
        });
    };

    popupTrigger = (triggerProps: any) => {
        const {
            isMobile,
            authDetails: { avatarUrl }
        } = this.props as any;
        return (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
            <div
                onClick={() =>
                    // close if open, open if closed
                    // @ts-ignore
                    this.showAccountMenu(!this.state.accountMenuDropdownOpen)
                }
                {...triggerProps}
                data-testid={
                    isMobile ? 'account-avatar_mobile' : 'account-avatar'
                }
                className="account__avatar"
            >
                <Avatar
                    // name attr is required in order to provide an aria-label
                    // for the avatar.
                    name="Account avatar"
                    appearance="circle"
                    size="medium"
                    // @ts-ignore
                    src={avatarUrl}
                    borderColor="transparent"
                    tabIndex={0}
                />
            </div>
        );
    };

    getAccountMenuConfig() {
        const {
            // @ts-ignore
            logoutLink,
            // @ts-ignore
            accountSwitcherEnabled,
            // @ts-ignore
            showPartnerRequestItems
        } = this.state;

        const {
            authDetails: { displayName, email: userEmail, avatarUrl }
        }: { authDetails: AuthDetailsInterface } = this.props as any;

        const accountMenuItems = [
            {
                type: MENU_ITEM_TYPES.LINK,
                title: translatableText.manageAccount.text,
                href: this.getViewAccountURL()
            }
        ];
        if (accountSwitcherEnabled) {
            accountMenuItems.push({
                type: MENU_ITEM_TYPES.LINK,
                title: translatableText.switchAccount.text,
                href: this.getSwitchAccountURL(),
                // @ts-ignore
                hasLozenge: true,
                dataAttributes: [
                    {
                        key: 'aria-label',
                        value: 'switch account link'
                    },
                    {
                        key: 'dataName',
                        value: 'switchAccountLink'
                    },
                    {
                        key: 'data-testid',
                        value: 'switch-account-link'
                    }
                ]
            });
        }

        let viewRequestDataAttributes = [
            {
                key: 'aria-label',
                value: 'view requests link'
            },
            {
                key: 'dataName',
                value: 'gsacRequestListLink'
            },
            {
                key: 'data-testid',
                value: 'GSAC-request-list-link'
            }
        ];
        if (showPartnerRequestItems) {
            viewRequestDataAttributes = [
                {
                    key: 'aria-label',
                    value: 'view partner requests link'
                },
                {
                    key: 'dataName',
                    value: 'viewPartnerRequests'
                },
                {
                    key: 'data-testid',
                    value: 'partners-request-list-link'
                }
            ];
        }
        const namedContactsDataAttributes = [
            {
                key: 'aria-label',
                value: 'manage named contacts link'
            },
            {
                key: 'dataName',
                value: 'manageNamedContacts'
            },
            {
                key: 'data-testid',
                value: 'manage-named-contacts-link'
            }
        ];
        const logOutDataAttributes = [
            {
                key: 'aria-label',
                value: 'log out link'
            },
            {
                key: 'dataName',
                value: 'logOut'
            },
            {
                key: 'data-testid',
                value: 'log-out-link'
            }
        ];

        return {
            menuTitle: translatableText.account.text,
            userDetails: {
                name: displayName,
                email: userEmail,
                avatarUrl
            },
            sections: [
                {
                    type: MENU_ITEM_TYPES.SUBMENU,
                    items: accountMenuItems
                },
                {
                    type: MENU_ITEM_TYPES.SEPARATOR
                },
                {
                    type: MENU_ITEM_TYPES.SUBMENU,
                    title: translatableText.support.text,
                    items: [
                        {
                            type: MENU_ITEM_TYPES.LINK,
                            title: translatableText.viewRequests.text,
                            href: getMyRequestsLink(),
                            dataAttributes: viewRequestDataAttributes
                        },
                        {
                            type: MENU_ITEM_TYPES.LINK,
                            title: translatableText.namedContacts.text,
                            href: getNamedContactsLink(),
                            dataAttributes: namedContactsDataAttributes
                        }
                    ]
                },
                {
                    type: MENU_ITEM_TYPES.SEPARATOR
                },
                {
                    type: MENU_ITEM_TYPES.SUBMENU,
                    items: [
                        {
                            type: MENU_ITEM_TYPES.LINK,
                            title: translatableText.logOut.text,
                            href: logoutLink,
                            dataAttributes: logOutDataAttributes
                        }
                    ]
                }
            ]
        };
    }

    // eslint-disable-next-line react/require-render-return
    render = () => {
        const {
            authDetails: { isAuthenticated }
        } = this.props;
        // @ts-ignore
        const isOpen = this.state.accountMenuDropdownOpen;

        // If the user is not logged in, we don't want to show the account menu
        if (!isAuthenticated) {
            return (
                <div className="account__login-btn">
                    <AtlasLink
                        data-testid={'login-button'}
                        // @ts-ignore
                        href={this.state.loginLink}
                        text={translatableText.logIn.text}
                        dataName={'log in button'}
                    />
                </div>
            );
        }

        const config = this.getAccountMenuConfig();

        return (
            <>
                <Popup
                    isOpen={isOpen}
                    onClose={() => this.showAccountMenu(false)}
                    placement="bottom-start"
                    content={() => (
                        <div>
                            <AccountMenuDialog {...config} />
                        </div>
                    )}
                    trigger={this.popupTrigger}
                />
                <div>{this.renderHeartbeat()}</div>
            </>
        );
    };
}

export default WithAuthDetails(AccountMenu);
