import kebabCase from 'lodash/kebabCase';
import {
    isEnabled as isEnabledOnBrowser,
    isMultiVariationEnabled as isMultiVariationEnabledOnBrowser
} from './strategies/browser-strategy';
import {
    isEnabled as isEnabledOnServer,
    isMultiVariationEnabled as isMultiVariationEnabledOnServer
} from './strategies/server-strategy';

declare const __isBrowser__: boolean;

const isFeatureFlagEnabled = __isBrowser__
    ? isEnabledOnBrowser
    : isEnabledOnServer;

const isMultiVariationEnabled = __isBrowser__
    ? isMultiVariationEnabledOnBrowser
    : isMultiVariationEnabledOnServer;

export async function isPublishingEnabled() {
    return await isFeatureFlagEnabled('publishing-pipeline-enabled', false);
}

export async function isLocalizationTagsEnabled(
    productSlug: string
): Promise<boolean> {
    const localizedContext = await isMultiVariationEnabled(
        'enable-localization',
        false
    );
    return localizedContext[kebabCase(productSlug)];
}

export async function isUnpublishRedirectCreationEnabled() {
    return await isFeatureFlagEnabled(
        'enable-unpublish-redirect-creation',
        false
    );
}

// this is used for testing, do not delete or use
export async function isNewFeatureEnabled() {
    return await isFeatureFlagEnabled('new-feature', false);
}

export async function isMajorAccessibilityImprovementsEnabled() {
    return await isFeatureFlagEnabled(
        'major-accessibility-improvements',
        false
    );
}

export async function isFlattenIAEnabled() {
    return await isFeatureFlagEnabled('flatten-ia', false);
}

export async function isAccountSwitcherEnabled() {
    return await isFeatureFlagEnabled('account-switcher-menu', false);
}
