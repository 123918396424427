import { Deployment, FilterValue } from '../pages/search-results/types';

export const getProductFiltersByDeployment = (
    productFilters: FilterValue[],
    deployment: Deployment
) => {
    return productFilters.filter((product) => product.platform === deployment);
};

export const getVersionFilters = (
    productQueryValue: string[],
    productFilters: FilterValue[]
) => {
    const versionFilterArray: FilterValue[] = [];

    const productId = productQueryValue[0];

    const productVersions = productFilters
        .filter((product) => product.key === productId)
        .map((product) => product.versions);

    productVersions.forEach((versionArray) => {
        if (versionArray) {
            versionArray.forEach((version) => {
                versionFilterArray.push({ key: version, name: version });
            });
        }
    });

    return versionFilterArray.sort((a, b) =>
        // Note: This is needed to sort the versions based on their last 1-2 decimal places
        // This is a temporary solution until the backend returns the versions in a sorted order
        b.key.localeCompare(a.key, undefined, {
            numeric: true,
            ignorePunctuation: true
        })
    );
};
