export enum Deployment {
    CLOUD = 'cloud',
    DATA_CENTER = 'data_center'
}

export interface FilterValue {
    key: string;
    name: string;
    platform?: Deployment;
    versions?: string[];
}

export enum Content {
    KNOWLEDGE_BASE = 'knowledge_base',
    DOCUMENTATION = 'documentation'
}

export const contentArray: FilterValue[] = [
    {
        name: 'Knowledge Base',
        key: Content.KNOWLEDGE_BASE
    },
    {
        name: 'Documentation',
        key: Content.DOCUMENTATION
    }
];

export const deploymentsArray: FilterValue[] = [
    {
        name: 'Cloud',
        key: Deployment.CLOUD
    },
    {
        name: 'Data Center',
        key: Deployment.DATA_CENTER
    }
];
