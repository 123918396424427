import React, { useState } from 'react';
import { CollectionTreeType, RightNavProps } from './sidebar.types';
import ChildPagesSection from './child-pages-section';
import SiblingPagesSection from './sibling-pages-section';
import './sidebar.less';
import analytics from '../../utils/analytics/analytics';
import get from 'lodash/get';

const RightNav = (props: RightNavProps) => {
    const {
        currentPageId,
        collectionTree,
        isProgressBarEnabled = false,
        topicSetRef = '',
        topicReferences = []
    }: RightNavProps = props;

    const [isTopicListCollapsed, setIsTopicListCollapsed]: [
        boolean,
        Function
    ] = useState(topicReferences.length > 5);

    let parent: CollectionTreeType | undefined;
    if (topicSetRef) {
        parent = collectionTree?.childList?.find(
            (obj) => obj.id === topicSetRef
        );
    } else {
        parent = collectionTree;
    }

    if (!parent) {
        return null;
    }

    const treeItems: CollectionTreeType[] = [];

    // if parent collection is guide, use currentTopicIndex to use it as conditional state
    let currentTopicIndex = -1;
    if (parent.childList) {
        parent.childList.forEach((val, index) => {
            // push siblings
            treeItems.push(val);
            if (val.id === currentPageId) {
                // get index of current topic in sidebar
                currentTopicIndex = index;
            }
        });
    }

    const loadMoreTopics = () => {
        analytics.trackEvent('showMore', {
            // @ts-ignore
            event: 'clicked',
            eventComponent: 'link',
            actionSubjectId: 'showMore',
            category: 'showMore',
            action: 'click',
            label: 'Show more',
            contentful_entry_id: get(
                window,
                '__APP_INITIAL_STATE__.entry.id',
                null
            )
        });
        setIsTopicListCollapsed(false);
    };

    return (
        <>
            {topicReferences[0] && (
                <ChildPagesSection
                    topicReferences={topicReferences}
                    loadMoreTopicsHandler={loadMoreTopics}
                    isTopicListCollapsed={isTopicListCollapsed}
                    maxTopicsWhenCollapsed={5}
                />
            )}
            <SiblingPagesSection
                parent={parent}
                treeItems={treeItems}
                currentPageId={currentPageId}
                currentTopicIndex={currentTopicIndex}
                isProgressBarEnabled={isProgressBarEnabled}
            />
        </>
    );
};

export default RightNav;
