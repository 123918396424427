import React from 'react';
import kebabCase from 'lodash/kebabCase';
import './community-card.less';
import { translatableText } from '../../utils/translatable-text';
import track from '../atlas-link/track';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import OpenIcon from '@atlaskit/icon/glyph/open';

interface CommunityCardProps {
    productName: string;
    deployment: string;
}

const CommunityCard = ({ productName, deployment }: CommunityCardProps) => {
    return (
        <div data-testid="community_card" className="community-card">
            <a
                href={`https://community.atlassian.com/t5/custom/page/page-id/create-post-step-1?add-tags=${kebabCase(
                    productName
                )},${deployment}&from-cac=true`}
                onClick={() =>
                    track(
                        `https://community.atlassian.com/t5/custom/page/page-id/create-post-step-1?add-tags=${kebabCase(
                            productName
                        )},${deployment}&from-cac=true`,
                        'Ask the Community',
                        'askTheCommunityButton'
                    )
                }
                rel="nofollow"
            >
                <header>
                    <CommentIcon label="comment" size="medium" />
                    <h2 className="sac-h3 h400">
                        {translatableText.community.text}
                    </h2>
                    <OpenIcon label="navigate" size="medium" />
                </header>
                <p>
                    Get answers to your questions from experts in the community
                </p>
            </a>
        </div>
    );
};

export default CommunityCard;
