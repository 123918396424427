import React, { useEffect, useState } from 'react';
import {
    DropdownMenuStateless,
    DropdownItemGroup,
    DropdownItem
} from '@atlaskit/dropdown-menu';
import './filter-selector.less';
import { FilterValue } from '../types';

interface FilterProps {
    filterTitle: string;
    filterValues: FilterValue[];
    setQueryValues: any;
    allowFilterAll?: boolean;
    queryByString?: boolean;
    shouldReset?: boolean;
    setShouldReset?: any;
    titleReset?: boolean;
    setTitleReset?: any;
    defaultSelected?: string;
    shouldPulse?: boolean;
    setShouldPulse?: any;
}

const FilterSelector = ({
    filterTitle,
    filterValues,
    setQueryValues,
    allowFilterAll = true,
    queryByString = false,
    shouldReset,
    setShouldReset,
    titleReset,
    setTitleReset,
    defaultSelected,
    shouldPulse = false,
    setShouldPulse
}: FilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(
        defaultSelected ? defaultSelected : filterTitle
    );

    // Clear filter button
    useEffect(() => {
        if (shouldReset) {
            setSelected(defaultSelected ? defaultSelected : filterTitle);
            setShouldReset(false);
        }
    }, [shouldReset, filterTitle, setShouldReset, defaultSelected]);

    // Edge case for when the title needs to be reset (used in product/deployment coupled filters)
    useEffect(() => {
        if (titleReset) {
            setSelected(defaultSelected ? defaultSelected : filterTitle);
            setTitleReset(false);
        }
    }, [filterTitle, setTitleReset, titleReset, defaultSelected]);

    return (
        <div
            className={`dropdown-container ${
                shouldPulse ? 'dropdown-container__pulse' : ''
            }`}
            data-testid={`filter-selector-${filterTitle}`}
        >
            <DropdownMenuStateless
                trigger={selected}
                triggerType="button"
                isOpen={isOpen}
                onOpenChange={({
                    isOpen: isOpenedNow
                }: {
                    isOpen: boolean;
                }) => {
                    setIsOpen(isOpenedNow);
                }}
            >
                <DropdownItemGroup title={filterTitle}>
                    {allowFilterAll && (
                        <DropdownItem
                            key={`All-${filterTitle}s`}
                            onClick={() => {
                                setSelected(`All ${filterTitle}s`);
                                if (setShouldPulse) {
                                    setShouldPulse(false);
                                }
                                // TODO: This is a hack for sending an empty array when selecting All Products.
                                // Once other filters expect an empty array, the else block can be removed.
                                if (filterTitle === 'Product') {
                                    setQueryValues([]);
                                } else {
                                    setQueryValues(
                                        filterValues.map(({ key }) => key)
                                    );
                                }
                            }}
                        >
                            {`All ${filterTitle}s`}
                        </DropdownItem>
                    )}
                    {filterValues.map(({ name, key }) => (
                        <DropdownItem
                            key={name}
                            onClick={() => {
                                setSelected(name);
                                if (setShouldPulse) {
                                    setShouldPulse(false);
                                }
                                setQueryValues(queryByString ? key : [key]);
                            }}
                        >
                            {name}
                        </DropdownItem>
                    ))}
                </DropdownItemGroup>
            </DropdownMenuStateless>
        </div>
    );
};

export default FilterSelector;
