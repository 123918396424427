import React from 'react';
import Footer from '../../components/footer';
import Breadcrumbs from '../../components/breadcrumbs';
import { Header } from '../../components/header';
import {
    CardGroup,
    CardWithCEM,
    CardsWithTitleAdjustment
} from '../../components/card';
import DeploymentSelector from '../../components/deployment-selector';
import { DocumentedProductPageType } from './documented-product.type';
import {
    PageTree,
    PageTreeList,
    PageTreeItem
} from '../../components/page-tree';
import './documented-product.less';
import { PageTreeType } from '../../utils/page-tree-type';
import AuthorTools from '../../components/author-tools';
import { translatableText } from '../../utils/translatable-text';
import { Group, List, ChildList } from './documentation-collection';
import { shape, bool } from 'prop-types';
import { CONTEXT_THEME } from '../../../contentful/content-types';
import {
    Search,
    handleOnSearch,
    handleOnSearchLegacy
} from '../../components/search';

import {
    isProductContext,
    isThemeContext
} from '../../../content/types/content';
import {
    getContextName,
    getSupportTitle,
    getDeployment
} from '../../../content/context-utils';
import NoticeHub from '../../components/notice-hub';
import { getContextDeploymentForSelectedButton } from '../../../content/context-utils/get-context-attributes';

// eslint-disable-next-line complexity
const SACDocumentedProduct = ({
    entry: {
        title,
        header,
        collectionReferences,
        id,
        tagline,
        icon,
        preview,
        context,
        versions,
        resources,
        relatedAppReferences
    },
    featureFlags: { isSearchEnabled },
    pageTree
}) => {
    const renderGuideCards = (guideCards) => {
        const count = guideCards.length;

        // if no Guide cards, don't display anything
        if (!count) {
            return null;
        }

        return (
            <CardGroup header="Guides" count={count}>
                <CardsWithTitleAdjustment collection={guideCards}>
                    {({ item, measureRef, titleHeight }) => (
                        <CardWithCEM
                            data-event-container="guidesCardGroup"
                            data-event-component={`guidesCard${item.id}`}
                            data-track-event="clicked"
                            tooltipContent="Edit this Collection"
                            key={item.id}
                            id={item.id}
                            title={item.title}
                            icon={item.icon ? item.icon.url : ''}
                            description={item.description}
                            buttonText={
                                item.buttonText
                                    ? item.buttonText
                                    : translatableText.viewTopics.text
                            }
                            iconDimensions={{
                                height: 100,
                                width: 100
                            }}
                            buttonLink={item.url}
                            measureRef={measureRef}
                            titleHeight={titleHeight}
                        />
                    )}
                </CardsWithTitleAdjustment>
            </CardGroup>
        );
    };

    const renderDocumentationCollectionSection = (collections) => {
        const nonGuideCollections = collections.filter(
            (collection) => collection.pageType !== 'Guide'
        );
        return (
            <Group>
                {nonGuideCollections.map(
                    ({ title, id, description, childrenReferences }) => (
                        <List title={title} key={id} description={description}>
                            <ChildList entries={childrenReferences} />
                        </List>
                    )
                )}
            </Group>
        );
    };

    const onSearch = isSearchEnabled ? handleOnSearch : handleOnSearchLegacy;
    const placeholder = isSearchEnabled
        ? translatableText.searchPlaceholder.text
        : 'Search documentation';
    const productName = !isThemeContext(context) ? getContextName(context) : '';
    const productVersion = !isThemeContext(context)
        ? getDeployment(context)
        : '';

    return (
        <>
            <AuthorTools
                pageId={id}
                tooltipContent="Edit this Product: Docs home"
            />
            <div className="documented-product" data-testid="page-template">
                <div className="content-wrapper">
                    <Header
                        title={getSupportTitle(context)}
                        id={header.id}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        relatedAppReferences={relatedAppReferences}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        additionalLinks={header.additionalLinks}
                        showProductDropdownAsWaffle={true}
                        showPreviewBanner={preview}
                        pageId={id}
                        resources={resources}
                        supportHomePath={
                            isProductContext(context)
                                ? context.deploymentUrls[
                                      context.deployment.toLowerCase()
                                  ] || context.deploymentUrls.default
                                : '/'
                        }
                        shouldRenderDocumentation
                        collections={collectionReferences}
                        supportFormUrls={{
                            authenticated: isProductContext(context)
                                ? context.customSupportFormAuthenticated
                                : null,
                            unauthenticated: isProductContext(context)
                                ? context.customSupportFormUnauthenticated
                                : null
                        }}
                    />

                    <div className="main-content-container flex-container margin-top-medium">
                        <PageTree context={context}>
                            <PageTreeList isRoot>
                                {pageTree.map(
                                    ({ title, slug, id, childList }) => (
                                        <PageTreeItem
                                            key={id}
                                            title={title}
                                            slug={slug}
                                            id={id}
                                            childList={childList}
                                        />
                                    )
                                )}
                            </PageTreeList>
                        </PageTree>
                        <main>
                            <div className="documented-product__sub-header">
                                <Breadcrumbs
                                    pageTree={pageTree}
                                    entryId={id}
                                    context={context}
                                />
                                {!(context.type === CONTEXT_THEME) && (
                                    <DeploymentSelector
                                        selected={getContextDeploymentForSelectedButton(
                                            context
                                        )}
                                        deployments={context.deploymentUrls}
                                        versions={versions}
                                    />
                                )}
                            </div>

                            {context.notice && (
                                <NoticeHub notice={context.notice} />
                            )}

                            <div className="documented-product__hero margin-top-medium">
                                <div className="documented-product__hero--primary">
                                    <h1>{title}</h1>
                                    {tagline && <h2>{tagline}</h2>}
                                    <div className="documented-product__search-container">
                                        <Search
                                            onSearch={onSearch}
                                            placeholder={placeholder}
                                            analyticsMetadata={{
                                                parentComponent:
                                                    'documented-product'
                                            }}
                                            legacyProps={{
                                                productName,
                                                productVersion
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="documented-product__hero--secondary">
                                    {icon && (
                                        <img
                                            src={icon.url}
                                            alt=""
                                            height="110"
                                            width="75"
                                        />
                                    )}
                                </div>
                            </div>
                            {renderGuideCards(
                                collectionReferences.filter(
                                    (collection) =>
                                        collection.pageType === 'Guide'
                                )
                            )}
                            {renderDocumentationCollectionSection(
                                collectionReferences
                            )}
                        </main>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        </>
    );
};

export default SACDocumentedProduct;

SACDocumentedProduct.propTypes = {
    entry: DocumentedProductPageType,
    pageTree: PageTreeType,
    featureFlags: shape({
        isDocumentationCollectionEnabled: bool,
        isSearchEnabled: bool
    })
};
