import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Content } from '../types';

export interface FilterState {
    contentTypeQueryValue: Content[];
    resetFilters: boolean;
}

const initialState: FilterState = {
    contentTypeQueryValue: Object.values(Content),
    resetFilters: false
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        resetAllValues: (state) => {
            state.contentTypeQueryValue = Object.values(Content);
            state.resetFilters = true;
        },
        setContentFilterQueryValue: (
            state,
            action: PayloadAction<Content[]>
        ) => {
            state.contentTypeQueryValue = action.payload;
        },
        setResetFilters: (state, action: PayloadAction<boolean>) => {
            state.resetFilters = action.payload;
        }
    }
});

export const { setContentFilterQueryValue, resetAllValues, setResetFilters } =
    filterSlice.actions;

export default filterSlice.reducer;
