import React, { useState } from 'react';
import Button from '@atlaskit/button';

import { Context } from '../../../content';
import { ProductCard } from './product-card';
import { translatableText } from '../../utils/translatable-text';
import analytics from '../../utils/analytics';

interface productListProps {
    productList: Context[];
}

export const ProductCardsGroup = ({ productList }: productListProps) => {
    const [shortList, setShortList] = useState(true);

    const handleOnClick = () => {
        setShortList(!shortList);

        analytics.trackEvent('toggle product card group', {
            // @ts-ignore
            event: 'toggled',
            eventComponent: 'product-card-group',
            actionSubjectId: 'link',
            category: 'product',
            action: 'toggled',
            label: 'toggle-product-card-group'
        });
    };

    return (
        <div>
            <div className="product-list-wrapper">
                {productList?.length > 0 &&
                    (shortList ? productList.slice(0, 5) : productList).map(
                        (product) => {
                            const {
                                productNameReference,
                                icon,
                                iconAlternate,
                                id,
                                deploymentUrls,
                                deployment
                            } = product;
                            if (deploymentUrls && icon) {
                                return (
                                    <ProductCard
                                        title={productNameReference.productName}
                                        icon={
                                            iconAlternate
                                                ? iconAlternate.url
                                                : icon.url
                                        }
                                        key={id}
                                        id={id}
                                        url={
                                            deployment === 'Cloud'
                                                ? deploymentUrls.cloud
                                                : deploymentUrls.server
                                        }
                                    />
                                );
                            }
                            return [];
                        }
                    )}
            </div>
            <Button appearance="link" onClick={handleOnClick}>
                {shortList
                    ? translatableText.seeAllOtherProducts.text
                    : translatableText.showLessProducts.text}
            </Button>
        </div>
    );
};
