import React, { ReactNode } from 'react';
import DropdownProducts from './products';
import DropdownResources from './resources';
import DropdownDocumentation from './documentation';
import DropdownSideNav, { AppAndThemeCards } from './side-nav';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
// @ts-ignore
import { ProductType } from '../product';
import DropdownGuides from './guides';
// @ts-ignore
import { GuideType } from '../../pages/collection/collection.type';
import './dropdown.less';
import { translatableText } from '../../utils/translatable-text';
import { GuidesMobileDropdownButton } from '../header/guides-dropdown-button';
import FocusLock from 'react-focus-lock';
import { CollectionReference } from '../../../content/types/content/collection';
import { SupportFormUrls } from '../contact';
import { AdditionalLinkType } from '../../pages/product-landing/product-landing-type';
import { Resources } from '../header';
import { Product } from '../product/product.type';
interface MobileDropdownProps {
    resources: Resources | undefined;
    products?: ProductType[];
    guides: GuideType[] | undefined;
    onChange: (type: string) => void;
    isOpen: boolean;
    collections: CollectionReference[] | undefined;
}

interface DropdownProps extends MobileDropdownProps {
    closeDropdown: () => void;
    type: string;
    id: string;
    cloudProducts: Product[];
    serverProducts: Product[];
    additionalLinks: AdditionalLinkType[];
    contentfulEditIcon?: ReactNode;
    className?: string;
    supportFormUrls?: SupportFormUrls;
    relatedAppReferences: AppAndThemeCards[] | undefined;
    showProductDropdownAsWaffle: boolean | undefined;
}

const MobileDropdownList = ({
    guides,
    resources,
    collections,
    products,
    onChange,
    isOpen
}: MobileDropdownProps) => (
    <div
        data-testid="mobile_dropdown"
        className={[
            'dropdown__mobile-list',
            isOpen ? '' : 'hide-dropdown'
        ].join(' ')}
    >
        {products && products.length && (
            <div
                data-testid="mobile_dropdown_item_products"
                className="dropdown__mobile-item"
                role="button"
                aria-label="Trigger products dropdown"
                tabIndex={0}
                onClick={() => {
                    onChange('Products');
                }}
                onKeyDown={(e) => {
                    e.keyCode === 13 && onChange('Products');
                }}
            >
                <span>{translatableText.products.text}</span>
                <ChevronRightIcon label="Products" />
            </div>
        )}
        {guides && (
            <GuidesMobileDropdownButton
                guides={guides}
                onClick={() => {
                    onChange('Get started');
                }}
            />
        )}
        {collections && collections?.length > 0 && (
            <div
                data-testid="mobile_dropdown_item_documentation"
                className="dropdown__mobile-item"
                role="button"
                aria-label="Trigger documentation dropdown"
                tabIndex={0}
                onClick={() => {
                    onChange('Documentation');
                }}
                onKeyDown={(e) => {
                    e.keyCode === 13 && onChange('Documentation');
                }}
            >
                <span>{translatableText.documentation.text}</span>
                <ChevronRightIcon label="Documentation" />
            </div>
        )}
        {resources && (
            <div
                data-testid="mobile_dropdown_item_resources"
                className="dropdown__mobile-item"
                role="button"
                aria-label="Trigger resources dropdown"
                tabIndex={0}
                onClick={() => {
                    onChange('Resources');
                }}
                onKeyDown={(e) => {
                    e.keyCode === 13 && onChange('Resources');
                }}
            >
                <span>{translatableText.resources.text}</span>
                <ChevronRightIcon label="Resources" />
            </div>
        )}
    </div>
);

// eslint-disable-next-line complexity
export const Dropdown = ({
    type,
    resources,
    guides,
    additionalLinks,
    contentfulEditIcon,
    className,
    isOpen,
    onChange,
    closeDropdown,
    supportFormUrls,
    collections,
    relatedAppReferences,
    cloudProducts,
    serverProducts,
    showProductDropdownAsWaffle
}: DropdownProps) => {
    const dropdownClassNames = ['dropdown'];

    let mobileProducts: ProductType[] = [];

    if (cloudProducts && serverProducts) {
        mobileProducts = [...new Set([...cloudProducts, ...serverProducts])];
    }

    if (!isOpen) {
        dropdownClassNames.push('hide-dropdown');
    }

    if (className) {
        dropdownClassNames.push(className);
    }

    return (
        <FocusLock returnFocus={true} noFocusGuards={true}>
            <div
                data-testid="dropdown_menu"
                className={dropdownClassNames.join(' ')}
            >
                <div className="dropdown__inner-container">
                    <div className="dropdown__main-content-wrapper dropdown__card-styling">
                        <DropdownProducts
                            closeDropdown={closeDropdown}
                            className="dropdown__main-content"
                            isOpen={type === 'Products'}
                            cloudProducts={cloudProducts}
                            serverProducts={serverProducts}
                            showProductDropdownAsWaffle={
                                showProductDropdownAsWaffle
                            }
                        />

                        <DropdownGuides
                            closeDropdown={closeDropdown}
                            className="dropdown__main-content"
                            isOpen={type === 'Get started'}
                            guides={guides}
                        />

                        <DropdownDocumentation
                            closeDropdown={closeDropdown}
                            className="dropdown__main-content"
                            isOpen={type === 'Documentation'}
                            collections={collections}
                        />

                        <DropdownResources
                            closeDropdown={closeDropdown}
                            className="dropdown__main-content"
                            isOpen={type === 'Resources'}
                            resources={resources}
                        />

                        <MobileDropdownList
                            products={mobileProducts}
                            resources={resources}
                            collections={collections}
                            guides={guides}
                            onChange={onChange}
                            isOpen={
                                type !== 'Products' &&
                                type !== 'Resources' &&
                                type !== 'Get started' &&
                                type !== 'Documentation'
                            }
                        />
                    </div>
                    <DropdownSideNav
                        className="dropdown__side-content dropdown__card-styling"
                        additionalLinks={additionalLinks}
                        relatedAppReferences={relatedAppReferences}
                        supportFormUrls={supportFormUrls}
                        type={type}
                    />
                    {contentfulEditIcon && contentfulEditIcon}
                </div>
            </div>
        </FocusLock>
    );
};
