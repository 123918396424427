import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import Spinner from '@atlaskit/spinner';
import { HeroBanner } from '../../components/hero-banner';
import { Search } from '../../components/search';
import Footer from '../../components/footer';
import { Header } from '../../components/header';
import './search-results.less';
import { translatableText } from '../../utils/translatable-text';
import {
    SearchResultsDisplay,
    SearchResultCard,
    NoSearchResults
} from '../../components/search-results';
import {
    sentryCaptureEvent,
    sentryCaptureException
} from '../../utils/sentry/sentry-client';
import FilterSelector from './components/filter-selector';
import ResetButton from './components/reset-button';
import {
    Deployment,
    deploymentsArray as deployments,
    type FilterValue,
    contentArray,
    Content
} from './types';
import {
    getProductFiltersByDeployment,
    getVersionFilters
} from '../../utils/get-filters';

interface SearchArticle {
    url: string;
    id: string;
    title: string;
    description: string;
    updatedDate: string;
    likes: number;
    views: number;
    version?: string;
    products: {
        platform: string[];
    }[];
}

interface FailedSearchResponse {
    timestamp: number;
    status: number;
    error: string;
    path: string;
}

interface SuccessfulSearchResponse {
    queryId: string;
    items: SearchArticle[];
}

type SearchResponse = FailedSearchResponse | SuccessfulSearchResponse;

interface SearchQuery {
    searchTerm: string;
    deployment?: Deployment;
    productsQueryValue?: string[];
    contentTypes?: Content[];
    version?: string[];
}

const HA_OVERRIDE_DOMAINS = new Set([
    'localhost',
    'support.dev.internal.atlassian.com',
    'support.uat.internal.atlassian.com'
]);

const queryHelpAggregator = async (
    query: SearchQuery,
    setResults: (results: SuccessfulSearchResponse) => void,
    setIsLoading: (loading: boolean) => void
) => {
    const helpAggregatorUrl = HA_OVERRIDE_DOMAINS.has(window.location.hostname)
        ? 'https://support.stg.internal.atlassian.com/gateway/api/help-content/content/search'
        : '/gateway/api/help-content/content/search';

    try {
        const response = await fetch(helpAggregatorUrl, {
            body: JSON.stringify({
                query: query.searchTerm,
                highlightSearchTerms: true,
                filters: {
                    platform: query.deployment,
                    // TODO: since not all right products, default (all) has limited results
                    product: query.productsQueryValue,
                    contentTypes: query.contentTypes,
                    version: query.version
                }
            }),
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
                'Sec-Fetch-Mode': 'cors',
                'Sec-Fetch-Site': 'same-origin',
                authority: 'support.dev.internal.atlassian.com'
            },
            method: 'POST'
        });

        const json: SearchResponse = await response.json();

        if (!response.ok) {
            const { error } = json as FailedSearchResponse;
            sentryCaptureEvent({
                event: {
                    message: error,
                    level: 'error',
                    contexts: {
                        response: {
                            status_code: response.status
                        }
                    },
                    extra: {
                        json
                    }
                }
            });

            setResults({
                queryId: '',
                items: []
            });
            setIsLoading(false);
            return;
        }

        const searchResults = json as SuccessfulSearchResponse;
        const items = searchResults?.items?.filter((items) => items) || [];
        setResults({
            queryId: searchResults.queryId,
            items
        });
    } catch (error) {
        sentryCaptureException({
            exception: error
        });
    } finally {
        setIsLoading(false);
    }
};

const debouncedQueryHelpAggregator = debounce(queryHelpAggregator, 500);

interface SearchResultsPageProps {
    entry: any;
    featureFlags: {
        isSearchTermHighlightingEnabled?: boolean;
    };
}

export const SearchResultsPage = ({
    entry,
    featureFlags
}: SearchResultsPageProps) => {
    const { isSearchTermHighlightingEnabled } = featureFlags;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [results, setResults] = useState<SuccessfulSearchResponse>({
        queryId: '',
        items: []
    });
    const [contentType, setContentType] = useState<Content[]>(
        Object.values(Content)
    );
    const [deployment, setDeployment] = useState<Deployment>(Deployment.CLOUD);
    const [shouldReset, setShouldReset] = useState(false);

    // Product Filter Values
    const [productsQueryValue, setProductsQueryValue] = useState<string[]>([]);
    const [listOfProducts, setListOfProducts] = useState<FilterValue[]>(
        getProductFiltersByDeployment(
            entry?.filters?.products,
            Deployment.CLOUD
        )
    );
    const [resetProductTitle, setResetProductTitle] = useState<boolean>(false);

    // Version Filter Values
    const [versionQueryValue, setVersionQueryValue] = useState<string[]>([]);
    const [listOfVersions, setListOfVersions] = useState<FilterValue[]>(
        getVersionFilters(productsQueryValue, entry?.filters?.products)
    );
    const [resetVersionTitle, setResetVersionTitle] = useState<boolean>(false);
    const showingVersionFilter = !(
        deployment === Deployment.CLOUD ||
        shouldReset ||
        (productsQueryValue.length === 0 &&
            deployment === Deployment.DATA_CENTER) ||
        !productsQueryValue
    );

    // Pulse states
    const [shouldPulseProductFilter, setShouldPulseProductFilter] =
        useState<boolean>(false);
    const [shouldPulseVersionFilter, setShouldPulseVersionFilter] =
        useState<boolean>(false);

    // Reset the product filter title and values when the deployment filter changes
    useEffect(() => {
        const products = getProductFiltersByDeployment(
            entry?.filters?.products,
            deployment
        );
        setListOfProducts(products);
        setResetProductTitle(true);
        setShouldPulseProductFilter(productsQueryValue.length > 0);
        setProductsQueryValue([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deployment]);

    // Reset the version filter values when the product filter changes
    useEffect(() => {
        if (showingVersionFilter) {
            const versions = getVersionFilters(
                productsQueryValue,
                entry?.filters?.products
            );
            setListOfVersions(versions);
            setResetVersionTitle(true);
            setShouldPulseVersionFilter(versionQueryValue.length > 0);
        }
        setVersionQueryValue([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsQueryValue]);

    const handleSubmit = (searchValue: string) => {
        if (!searchValue) {
            return;
        }

        setIsLoading(true);
        // keep the search value and url in sync
        const url = new URL(window.location.href);
        url.searchParams.set('searchTerm', searchValue);
        window.history.replaceState({}, '', url);

        debouncedQueryHelpAggregator(
            {
                searchTerm: searchValue,
                deployment,
                productsQueryValue,
                contentTypes: contentType,
                version: versionQueryValue
            },
            setResults,
            setIsLoading
        );
    };

    useEffect(() => {
        handleSubmit(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deployment, productsQueryValue, contentType, versionQueryValue]);

    // initialize the query from the url for when users first visit
    useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const searchTermFromUrl = parsedUrl.searchParams.get('searchTerm');
        setSearchTerm(searchTermFromUrl || '');
    }, []);

    const renderVersionFilter = () => {
        if (showingVersionFilter) {
            return (
                <>
                    <FilterSelector
                        filterTitle="Version"
                        filterValues={listOfVersions}
                        setQueryValues={setVersionQueryValue}
                        allowFilterAll={false}
                        shouldReset={shouldReset}
                        setShouldReset={setShouldReset}
                        titleReset={resetVersionTitle}
                        setTitleReset={setResetVersionTitle}
                        shouldPulse={shouldPulseVersionFilter}
                        setShouldPulse={setShouldPulseVersionFilter}
                    />
                </>
            );
        }
    };

    return (
        <div className="content-wrapper">
            <Header
                id={entry.header.id}
                pageId={entry.id}
                resources={entry.resources}
                additionalLinks={entry.header.additionalLinks}
                atlassianLogo={entry.header.atlassianLogo}
                logo={entry.header.atlassianSupportLogo.url}
                showPreviewBanner={entry.preview}
                atlassianSupportLogo={entry.header.atlassianSupportLogo}
                serverProducts={entry.header.serverProducts}
                cloudProducts={entry.header.cloudProducts}
            />
            <main>
                <div data-testid="search-results-page">
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleSubmit}
                                placeholder={
                                    translatableText.searchPlaceholder.text
                                }
                                initialValue={searchTerm}
                                analyticsMetadata={{
                                    parentComponent: 'search-results'
                                }}
                                onChange={setSearchTerm}
                            />
                        }
                    />
                    <div className="filters-container">
                        <FilterSelector
                            filterTitle="Deployment"
                            filterValues={deployments}
                            setQueryValues={setDeployment}
                            allowFilterAll={false}
                            queryByString={true}
                            shouldReset={shouldReset}
                            setShouldReset={setShouldReset}
                            defaultSelected={deployments[0].name}
                        />
                        <FilterSelector
                            filterTitle="Product"
                            filterValues={listOfProducts}
                            setQueryValues={setProductsQueryValue}
                            shouldReset={shouldReset}
                            setShouldReset={setShouldReset}
                            titleReset={resetProductTitle}
                            setTitleReset={setResetProductTitle}
                            shouldPulse={shouldPulseProductFilter}
                            setShouldPulse={setShouldPulseProductFilter}
                        />
                        {renderVersionFilter()}
                        <FilterSelector
                            filterTitle="Resource Type"
                            filterValues={contentArray}
                            setQueryValues={setContentType}
                            allowFilterAll={true}
                            shouldReset={shouldReset}
                            setShouldReset={setShouldReset}
                        />
                        <ResetButton
                            onReset={() => {
                                setShouldReset(true);
                                setDeployment(Deployment.CLOUD);
                                setProductsQueryValue([]);
                                setListOfProducts(
                                    getProductFiltersByDeployment(
                                        entry?.filters?.products,
                                        Deployment.CLOUD
                                    )
                                );
                                setContentType(Object.values(Content));
                                setVersionQueryValue([]);
                                setListOfVersions([]);
                            }}
                        />
                    </div>
                    <SearchResultsDisplay>
                        {isLoading ? (
                            <div className="search-results-spinner-container">
                                <Spinner size={'xlarge'} />
                            </div>
                        ) : results.queryId && results.items?.length ? (
                            results.items.map((result: any, index: number) => (
                                <SearchResultCard
                                    key={result.id}
                                    id={result.id}
                                    title={
                                        isSearchTermHighlightingEnabled
                                            ? result?.highlights?.title ||
                                              result.title
                                            : result.title
                                    }
                                    description={
                                        isSearchTermHighlightingEnabled
                                            ? result?.highlights?.description ||
                                              result.description
                                            : result.description
                                    }
                                    href={result.url}
                                    dateModified={result.updatedDate}
                                    markedUseful={result.likes}
                                    views={result.views}
                                    searchTerm={searchTerm}
                                    position={index + 1}
                                    queryId={results.queryId}
                                    products={result.products}
                                    deployment={deployment}
                                    isSearchTermHighlightingEnabled={
                                        isSearchTermHighlightingEnabled
                                    }
                                />
                            ))
                        ) : (
                            <NoSearchResults searchTerm={searchTerm} />
                        )}
                    </SearchResultsDisplay>
                </div>
            </main>
            <Footer
                logo={entry.header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};
