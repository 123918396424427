import React from 'react';
import './kb-home.less';
import { KbHomePage } from '../../../content/types/content/kb-home';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import { PAGE_KB_HOME } from '../../../contentful/content-types';
import { Header, HeaderListItem } from '../../components/header';
import Footer from '../../components/footer';
import HelpLinks from '../../components/help-links';
import { getSupportTitle } from '../../../content/context-utils';
import { Search } from '../../components/search';
import { translatableText } from '../../utils/translatable-text';

export type KbHomePageEntry = KbHomePage & {
    // TODO see https://hello.jira.atlassian.cloud/browse/CSTE-2182 for more on header and resources typing
    header: any;
    resources: any;
    preview: boolean;
    metadata: any;
};

const KbHomeSearch = ({ title }: { title: string }) => {
    return (
        <div className="kb-home__search">
            <h1 className="margin-bottom-medium">{title} Knowledge Base</h1>
            <Search
                className="kb-home__search-bar"
                analyticsMetadata={{ parentComponent: 'kb-home' }}
                placeholder={translatableText.kbHomeSearchPlaceholder.text}
                onSearch={(searchTerm) =>
                    window.location.assign(
                        `/search-results/?searchTerm=${encodeURIComponent(
                            searchTerm
                        )}`
                    )
                }
            />
        </div>
    );
};

const KbHome = (props: { entry: KbHomePageEntry }) => {
    const { title, url, id, header, preview, resources } = props.entry;

    const headerListItem = (
        <HeaderListItem
            label={translatableText.knowledgeBase.text}
            href={url}
        />
    );

    return (
        <div className="kb-home flex-column-page">
            <div className="content">
                <Header
                    title={getSupportTitle(props.entry)}
                    id={header.id}
                    pageId={id}
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    additionalLinks={header.additionalLinks}
                    primaryListItems={headerListItem}
                    showProductDropdownAsWaffle={true}
                    showPreviewBanner={preview}
                    resources={resources}
                    logo={header.logo}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    atlassianLogo={header.atlassianLogo}
                    shouldRenderDocumentation={false}
                    supportFormUrls={{
                        authenticated: null,
                        unauthenticated: null
                    }}
                />
                <div className="main-content-container flex-container margin-top-medium">
                    <main className="kb-home__container">
                        <div className="kb-home__sub-header">
                            <Breadcrumbs
                                entryId={id}
                                pageTree={[]}
                                type={PAGE_KB_HOME}
                                context={{
                                    kbHomePageReference: {
                                        url,
                                        title
                                    }
                                }}
                            />
                        </div>
                        <article
                            className="kb-home__inner-container margin-top-medium"
                            id="maincontent"
                        >
                            <div className="kb-home__body">
                                <KbHomeSearch title={title} />
                                <hr className="kb-home__divider" />
                                <HelpLinks />
                            </div>
                        </article>
                    </main>
                </div>
            </div>
            <Footer
                logo={header.atlassianLogo.url}
                className="margin-top-large"
            />
        </div>
    );
};

export default KbHome;
