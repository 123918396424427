import React, { Component, ReactNode } from 'react';
import SearchIcon from '@atlaskit/icon/glyph/search';
import MoreIcon from '@atlaskit/icon/glyph/more';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import Dropdown from '../dropdown';
import { CAC_SEARCH_API } from '../../utils/constants';
import { TABLET_BREAKPOINT } from '../../../common/constants';
import { ContactListItem } from '../contact';
// @ts-ignore
import AccountMenu from '../account-menu';
import { track } from '../atlas-link';
// @ts-ignore
import PreviewBanner from '../preview-banner';
import './header.less';
import Bannerman from '../bannerman';
import { translatableText } from '../../utils/translatable-text';
import { GuidesDropdownButton } from './guides-dropdown-button';
import analytics from '../../utils/analytics/analytics';
import AtlasLink from '../atlas-link/atlas-link';
import { GuideType } from '../../pages/collection/guide.type';

import {
    DOCUMENTATION,
    DOCUMENTATION_DROPDOWN,
    GET_STARTED,
    GET_STARTED_DROPDOWN,
    PRODUCTS,
    PRODUCTS_DROPDOWN,
    RESOURCES,
    RESOURCES_DROPDOWN,
    WAFFLE_MENU,
    keyCodes
} from './constants';
import { AdditionalLinkType } from '.';
import { CollectionReference } from 'src/content/types/content/collection';
import { Product } from '../product/product.type';
import { AppAndThemeCards } from '../dropdown/side-nav';
import { CardResource } from '../card/card.type';

const getActionSubjectIdFromDropdownType = (type: string) => {
    switch (type) {
        case DOCUMENTATION:
            return DOCUMENTATION_DROPDOWN;
        case GET_STARTED:
            return GET_STARTED_DROPDOWN;
        case PRODUCTS:
            return PRODUCTS_DROPDOWN;
        case RESOURCES:
            return RESOURCES_DROPDOWN;
        default:
            return '';
    }
};

export interface Resources {
    id: string;
    cards: CardResource[];
    type: string;
}
export interface HeaderProps {
    resources: Resources | undefined;
    hideContactButton?: boolean;
    // Title overrides the Atlassian Logo in the header if provided
    title?: string | null;
    showProductDropdownAsWaffle?: boolean;
    primaryListItems?: ReactNode;
    secondaryListItems?: ReactNode;
    additionalLinks: AdditionalLinkType[];
    showPartnerRequestItems?: boolean;
    logo: string;
    id: string;
    showPreviewBanner: boolean;
    pageId: string;
    supportHomePath?: string;
    supportFormUrls?: {
        authenticated: string | null;
        unauthenticated: string | null;
    };
    shouldRenderDocumentation?: boolean;
    collections?: CollectionReference[];
    atlassianLogo: { url: string };
    atlassianSupportLogo: { url: string };
    serverProducts: Product[];
    cloudProducts: Product[];
    relatedAppReferences?: AppAndThemeCards[] | undefined;
}

interface HeaderState {
    dropdown: {
        isOpen: boolean;
        type: string;
        mobile?: boolean | null;
    };
}

export default class Header extends Component<HeaderProps, HeaderState> {
    headerRef = React.createRef<HTMLDivElement>();

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            dropdown: {
                isOpen: false,
                type: '',
                mobile: null
            }
        };
    }

    componentDidMount() {
        window.addEventListener(
            'resize',
            debounce(this.updateViewport.bind(this), 100)
        );
        window.addEventListener('mousedown', this.onOutsideClick);
        this.updateViewport();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateViewport);
        window.removeEventListener('mousedown', this.onOutsideClick);
    }

    search() {
        track(CAC_SEARCH_API, 'Search', 'search button');
        window.open(CAC_SEARCH_API, '_blank');
    }

    setStateOpenMobileDropdown = () => {
        this.setState({
            dropdown: {
                type: '',
                isOpen: true,
                mobile: true
            }
        });
        analytics.trackEvent('mobileDropdown', {
            // @ts-ignore
            event: 'clicked',
            eventComponent: 'menu',
            actionSubjectId: 'mobileDropdown',
            category: 'header dropdown',
            action: 'click',
            label: 'mobile dropdown',
            contentful_entry_id: get(
                window,
                '__APP_INITIAL_STATE__.entry.id',
                null
            )
        });
    };

    updateViewport() {
        const { isOpen, type } = this.state.dropdown;
        if (
            window.innerWidth < TABLET_BREAKPOINT &&
            !this.state.dropdown.mobile
        ) {
            this.setState({
                dropdown: {
                    isOpen,
                    type,
                    mobile: true
                }
            });
        } else if (
            window.innerWidth >= TABLET_BREAKPOINT &&
            this.state.dropdown.mobile
        ) {
            this.setState({
                dropdown: {
                    isOpen,
                    type,
                    mobile: false
                }
            });
        }
    }

    handleDropdownEvent({
        shouldClose = false,
        actionSubjectId = '',
        type = ''
    } = {}) {
        this.setState(
            ({
                dropdown: { isOpen: wasOpen, type: previousType }
            }: HeaderState) => {
                // Need a force-closing functionality.
                if (shouldClose) {
                    return {
                        dropdown: { isOpen: false, type: '' }
                    };
                }

                // Not providing a type means dropdown should be toggled open or closed.
                if (!type) {
                    return {
                        dropdown: {
                            isOpen: !wasOpen,
                            type: ''
                        }
                    };
                }

                // Logic for dropdowns with types that receive clicks.
                const willBeOpened = type !== previousType;
                const newType = willBeOpened ? type : '';
                return {
                    dropdown: {
                        isOpen: willBeOpened,
                        type: newType
                    }
                };
            }
        );
        actionSubjectId =
            actionSubjectId || getActionSubjectIdFromDropdownType(type);
        this.trackDropdownAnalytics(type, actionSubjectId);
    }

    onOutsideClick = (e: MouseEvent) => {
        // Handles logic for clicking outside of the dropdown.
        const { current: header } = this.headerRef;
        const { target: clickedElement } = e;

        const headerContainsElement = header
            ? header.contains(clickedElement as Node)
            : false;

        if (headerContainsElement) {
            return;
        }

        const { isOpen: dropdownOpen } = this.state.dropdown;

        if (dropdownOpen && !headerContainsElement) {
            this.handleDropdownEvent({ shouldClose: true });
        }
    };

    // type change handler will only be called on a mobile screen size
    onTypeChangeHandler(type: string) {
        const { isOpen } = this.state.dropdown;
        this.setState({
            dropdown: {
                isOpen,
                type,
                mobile: true
            }
        });
    }

    getDropdownAriaLabel(menu?: string) {
        const { isOpen } = this.state.dropdown;

        if (menu) {
            return isOpen ? `Close ${menu} dropdown` : `Open ${menu} dropdown`;
        }

        return isOpen ? `Close dropdown` : `Open dropdown`;
    }

    trackDropdownAnalytics(type: string, actionSubjectId: string) {
        const shouldToggleDropdown = !type || this.state.dropdown.type === type;
        const isOpen = !shouldToggleDropdown;
        // type can be an empty string, so conditionally
        // generate the event name
        const dropdownType = type.length > 0 ? type.toLowerCase() + ' ' : type;
        analytics.trackEvent(camelCase(dropdownType + 'dropdown'), {
            // @ts-ignore
            event: isOpen ? 'opened' : 'closed',
            eventComponent: 'menu',
            actionSubjectId: actionSubjectId,
            category: 'header dropdown',
            action: 'click',
            label: type,
            contentful_entry_id: get(
                window,
                '__APP_INITIAL_STATE__.entry.id',
                null
            )
        });
    }

    renderHeaderControls() {
        const { type, mobile } = this.state.dropdown;
        const { title, atlassianSupportLogo, supportHomePath } = this.props;

        return (
            <>
                {mobile && type ? (
                    <div className="header__mobile-nav">
                        <div
                            role="button"
                            tabIndex={0}
                            aria-label="Open dropdown"
                            onClick={this.setStateOpenMobileDropdown}
                            onKeyDown={(e) => {
                                e.keyCode === keyCodes.RETURN &&
                                    this.setStateOpenMobileDropdown();
                            }}
                        >
                            <ChevronLeftLargeIcon label="mobile" />
                        </div>
                        <h2>{type}</h2>
                    </div>
                ) : (
                    <AtlasLink
                        href={supportHomePath ? supportHomePath : '/'}
                        className="header__nav-title"
                        dataName={
                            supportHomePath
                                ? 'supportHeaderButton'
                                : 'supportHomeButton'
                        }
                    >
                        {title ? (
                            <h2 data-testid="header-support-link">{title}</h2>
                        ) : (
                            <div className="logo-container">
                                <img
                                    src={atlassianSupportLogo.url}
                                    alt="Atlassian Logo"
                                    data-testid="atlassian-logo-nav"
                                />
                            </div>
                        )}
                    </AtlasLink>
                )}
            </>
        );
    }

    renderProductsDropdown() {
        const { cloudProducts, serverProducts, showProductDropdownAsWaffle } =
            this.props;
        if (!cloudProducts || !serverProducts) {
            return null;
        }

        return (
            <li
                // hide if showProductDropdownAsWaffle prop passed
                style={
                    showProductDropdownAsWaffle
                        ? { display: 'none' }
                        : { display: 'inherit' }
                }
            >
                <div
                    className="header__nav-item"
                    role="button"
                    tabIndex={0}
                    aria-label={this.getDropdownAriaLabel('products')}
                    onClick={() =>
                        this.handleDropdownEvent({
                            type: PRODUCTS
                        })
                    }
                    onKeyDown={(e) =>
                        e.keyCode === keyCodes.RETURN &&
                        this.handleDropdownEvent({
                            type: PRODUCTS
                        })
                    }
                >
                    <span data-testid="products-dropdown">{`${translatableText.products.text} `}</span>
                    <ChevronDownIcon label="products" />
                </div>
            </li>
        );
    }

    renderResourcesDropdown() {
        const { resources } = this.props;

        if (!resources) {
            return null;
        }

        return (
            <li>
                <div
                    className="header__nav-item"
                    role="button"
                    tabIndex={0}
                    aria-label={this.getDropdownAriaLabel('resources')}
                    onClick={() =>
                        this.handleDropdownEvent({
                            type: RESOURCES
                        })
                    }
                    onKeyDown={(e) =>
                        e.keyCode === keyCodes.RETURN &&
                        this.handleDropdownEvent({
                            type: RESOURCES
                        })
                    }
                >
                    <span data-testid="resources-dropdown">
                        {translatableText.resources.text}
                    </span>
                    <ChevronDownIcon label="Resources Dropdown" />
                </div>
            </li>
        );
    }

    renderDocumentationDropdown() {
        const { shouldRenderDocumentation } = this.props;

        if (!shouldRenderDocumentation) {
            return null;
        }

        return (
            <li>
                <div
                    className="header__nav-item"
                    role="button"
                    tabIndex={0}
                    aria-label={this.getDropdownAriaLabel('documentation')}
                    onClick={() =>
                        this.handleDropdownEvent({
                            type: DOCUMENTATION
                        })
                    }
                    onKeyDown={(e) =>
                        e.keyCode === keyCodes.RETURN &&
                        this.handleDropdownEvent({
                            type: DOCUMENTATION
                        })
                    }
                    data-testid="documentation-dropdown"
                >
                    <span>{translatableText.documentation.text}</span>
                    <ChevronDownIcon label="Documentation Dropdown" />
                </div>
            </li>
        );
    }

    renderContactButton() {
        const { hideContactButton, supportFormUrls } = this.props;
        if (hideContactButton) {
            return null;
        }

        return (
            <li>
                <div className="header__nav-item contact-btn">
                    <ContactListItem supportFormUrls={supportFormUrls} />
                </div>
            </li>
        );
    }

    renderWaffleMenu() {
        const { mobile } = this.state.dropdown;
        const { showProductDropdownAsWaffle } = this.props;

        return (
            <>
                {!mobile && showProductDropdownAsWaffle && (
                    <div
                        data-testid="waffle_menu"
                        className="header__nav-item header__nav-item--waffle"
                        onClick={() =>
                            this.handleDropdownEvent({
                                type: PRODUCTS,
                                actionSubjectId: WAFFLE_MENU
                            })
                        }
                        role="button"
                        tabIndex={0}
                        aria-label="Open products dropdown"
                        onKeyDown={(e) =>
                            e.keyCode === keyCodes.RETURN &&
                            this.handleDropdownEvent({
                                type: PRODUCTS,
                                actionSubjectId: WAFFLE_MENU
                            })
                        }
                    >
                        <AppSwitcherIcon label="Products Dropdown" />
                    </div>
                )}
            </>
        );
    }

    // eslint-disable-next-line complexity
    render() {
        const { isOpen, type } = this.state.dropdown;
        const {
            cloudProducts,
            serverProducts,
            resources,
            primaryListItems,
            secondaryListItems,
            additionalLinks,
            showPartnerRequestItems,
            id,
            relatedAppReferences,
            showPreviewBanner,
            supportFormUrls,
            collections,
            showProductDropdownAsWaffle
        } = this.props;

        const guides = collections?.filter(
            (guide) => guide.pageType === 'Guide'
        );

        return (
            <div
                className="header-components"
                data-testid="global_header"
                data-event-container="globalHeader"
                ref={this.headerRef}
            >
                <Bannerman />
                {showPreviewBanner && <PreviewBanner />}
                <header
                    className={
                        showPreviewBanner ? 'subheader header' : 'header'
                    }
                >
                    <div className="header__inner-container">
                        <div className="header__inner-content">
                            <div
                                className="header__nav"
                                data-testid="page-header"
                                data-event-container="pageHeader"
                            >
                                <div className="header__nav--logo">
                                    {this.renderWaffleMenu()}

                                    {this.renderHeaderControls()}
                                </div>
                                <div className="header__nav--primary">
                                    <ul className="header__nav-list">
                                        {this.renderProductsDropdown()}
                                        {guides && (
                                            <GuidesDropdownButton
                                                guides={guides as GuideType[]}
                                                onClick={() =>
                                                    this.handleDropdownEvent({
                                                        type: GET_STARTED
                                                    })
                                                }
                                            />
                                        )}
                                        {primaryListItems}
                                        {this.renderDocumentationDropdown()}
                                        {this.renderResourcesDropdown()}
                                    </ul>
                                </div>
                            </div>
                            <div className="header__nav--secondary">
                                <ul className="header__nav-list">
                                    {secondaryListItems}
                                    {this.renderContactButton()}
                                    <li>
                                        <div
                                            data-testid="search_button"
                                            className="header__nav-item header__nav-item--icon header__nav-item--search-icon"
                                            role="button"
                                            tabIndex={0}
                                            aria-label="Open search page"
                                            onClick={this.search}
                                            onKeyDown={(e) => {
                                                e.keyCode === keyCodes.RETURN &&
                                                    this.search();
                                            }}
                                        >
                                            <SearchIcon label="Search Page" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="header__nav-item account-btn-wrapper">
                                            <AccountMenu
                                                showPartnerRequestItems={
                                                    showPartnerRequestItems
                                                }
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className="header__nav-item header__nav-item--icon header__nav-item--dropdown-button"
                                            role="button"
                                            tabIndex={0}
                                            aria-label={this.getDropdownAriaLabel()}
                                            onClick={() =>
                                                this.handleDropdownEvent()
                                            }
                                            onKeyDown={(e) => {
                                                e.keyCode === keyCodes.RETURN &&
                                                    this.handleDropdownEvent();
                                            }}
                                        >
                                            {isOpen ? (
                                                <CrossIcon label="Dropdown Open" />
                                            ) : (
                                                <MoreIcon label="Dropdown Not Open" />
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="header__nav--mobile">
                                <ul className="header__nav-list">
                                    <li>
                                        <div
                                            className="header__nav-item header__nav-item--icon header__nav-item--search-icon"
                                            role="button"
                                            tabIndex={0}
                                            aria-label="Open search page"
                                            onClick={this.search}
                                            onKeyDown={(e) => {
                                                e.keyCode === keyCodes.RETURN &&
                                                    this.search();
                                            }}
                                        >
                                            <SearchIcon label="Open search page" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="header__nav-item account-btn-wrapper">
                                            <AccountMenu
                                                showPartnerRequestItems={
                                                    showPartnerRequestItems
                                                }
                                                isMobile={true}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className="header__nav-item header__nav-item--icon"
                                            role="button"
                                            tabIndex={0}
                                            aria-label={
                                                isOpen
                                                    ? 'Close dropdown'
                                                    : 'Open dropdown'
                                            }
                                            onClick={() =>
                                                this.handleDropdownEvent()
                                            }
                                            onKeyDown={(e) =>
                                                e.keyCode === keyCodes.RETURN &&
                                                this.handleDropdownEvent()
                                            }
                                        >
                                            {isOpen ? (
                                                <CrossIcon label="Close Dropdown" />
                                            ) : (
                                                <MoreIcon label="Open Dropdown" />
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
                <Dropdown
                    closeDropdown={() => {
                        this.handleDropdownEvent({ shouldClose: true });
                    }}
                    onChange={this.onTypeChangeHandler.bind(this)}
                    isOpen={isOpen}
                    id={id}
                    relatedAppReferences={relatedAppReferences}
                    type={type}
                    additionalLinks={additionalLinks}
                    resources={resources}
                    serverProducts={serverProducts}
                    cloudProducts={cloudProducts}
                    guides={guides}
                    supportFormUrls={supportFormUrls}
                    collections={collections}
                    showProductDropdownAsWaffle={showProductDropdownAsWaffle}
                />
            </div>
        );
    }
}
