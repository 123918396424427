import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import Spinner from '@atlaskit/spinner';
import { HeroBanner } from '../../components/hero-banner';
import { Search } from '../../components/search';
import Footer from '../../components/footer';
import { Header } from '../../components/header';
import './search-results.less';
import { translatableText } from '../../utils/translatable-text';
import {
    SearchResultsDisplay,
    SearchResultCard,
    NoSearchResults
} from '../../components/search-results';
import FilterSelector from './components/filter-selector';
import ResetButton from './components/reset-button';
import {
    Deployment,
    deploymentsArray as deployments,
    type FilterValue,
    contentArray,
    Content
} from './types';
import {
    getProductFiltersByDeployment,
    getVersionFilters
} from '../../utils/get-filters';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from './state/store';
import { updateSearchTerm } from './state/search/search-reducer';
import {
    SearchQuery,
    fetchSearchResults
} from './state/search/utils/fetch-search-results';
import Logger from '../../../logger';
import {
    resetAllValues,
    setContentFilterQueryValue
} from './state/filter-reducer';

const searchHandler = async (
    dispatch: AppDispatch,
    searchTerm: string,
    query: SearchQuery
) => {
    try {
        dispatch(
            fetchSearchResults({
                searchTerm,
                query
            })
        );
    } catch (error) {
        Logger.error({ error }, 'Error dispatching the search handler');
    }
};

const debouncedQueryHelpAggregator = debounce(searchHandler, 500);

interface SearchResultsPageProps {
    entry: any;
    featureFlags: {
        isSearchTermHighlightingEnabled?: boolean;
    };
}

export const SearchResultsPage = ({
    entry,
    featureFlags
}: SearchResultsPageProps) => {
    const { isSearchTermHighlightingEnabled } = featureFlags;

    const { isLoading, searchTerm, searchResults } = useSelector(
        (state: RootState) => state.search
    );

    const { contentTypeQueryValue, resetFilters } = useSelector(
        (state: RootState) => state.filter
    );

    const dispatch: AppDispatch = useDispatch();
    const handleSearchChange = (searchValue: string) => {
        const action = updateSearchTerm(searchValue);
        dispatch(action);
    };

    const [deployment, setDeployment] = useState<Deployment>(Deployment.CLOUD);

    // Product Filter Values
    const [productsQueryValue, setProductsQueryValue] = useState<string[]>([]);
    const [listOfProducts, setListOfProducts] = useState<FilterValue[]>(
        getProductFiltersByDeployment(
            entry?.filters?.products,
            Deployment.CLOUD
        )
    );
    const [resetProductTitle, setResetProductTitle] = useState<boolean>(false);

    // Version Filter Values
    const [versionQueryValue, setVersionQueryValue] = useState<string[]>([]);
    const [listOfVersions, setListOfVersions] = useState<FilterValue[]>(
        getVersionFilters(productsQueryValue, entry?.filters?.products)
    );
    const [resetVersionTitle, setResetVersionTitle] = useState<boolean>(false);
    const showingVersionFilter = !(
        deployment === Deployment.CLOUD ||
        resetFilters ||
        (productsQueryValue.length === 0 &&
            deployment === Deployment.DATA_CENTER) ||
        !productsQueryValue
    );

    // Pulse states
    const [shouldPulseProductFilter, setShouldPulseProductFilter] =
        useState<boolean>(false);
    const [shouldPulseVersionFilter, setShouldPulseVersionFilter] =
        useState<boolean>(false);

    // Reset the product filter title and values when the deployment filter changes
    useEffect(() => {
        const products = getProductFiltersByDeployment(
            entry?.filters?.products,
            deployment
        );
        setListOfProducts(products);
        setResetProductTitle(true);
        setShouldPulseProductFilter(productsQueryValue.length > 0);
        setProductsQueryValue([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deployment]);

    // Reset the version filter values when the product filter changes
    useEffect(() => {
        if (showingVersionFilter) {
            const versions = getVersionFilters(
                productsQueryValue,
                entry?.filters?.products
            );
            setListOfVersions(versions);
            setResetVersionTitle(true);
            setShouldPulseVersionFilter(versionQueryValue.length > 0);
        }
        setVersionQueryValue([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsQueryValue]);

    const handleSubmit = (searchValue: string) => {
        if (!searchValue) {
            return;
        }

        // keep the search value and url in sync
        const url = new URL(window.location.href);
        url.searchParams.set('searchTerm', searchValue);
        window.history.replaceState({}, '', url);

        debouncedQueryHelpAggregator(dispatch, searchTerm, {
            deployment,
            productsQueryValue,
            contentTypes: contentTypeQueryValue,
            version: versionQueryValue
        });
    };

    useEffect(() => {
        handleSubmit(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        deployment,
        productsQueryValue,
        contentTypeQueryValue,
        versionQueryValue
    ]);

    // initialize the query from the url for when users first visit
    useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const searchTermFromUrl = parsedUrl.searchParams.get('searchTerm');
        dispatch(updateSearchTerm(searchTermFromUrl || ''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderVersionFilter = () => {
        if (showingVersionFilter) {
            return (
                <>
                    <FilterSelector
                        filterTitle="Version"
                        filterValues={listOfVersions}
                        setQueryValues={setVersionQueryValue}
                        allowFilterAll={false}
                        titleReset={resetVersionTitle}
                        setTitleReset={setResetVersionTitle}
                        shouldPulse={shouldPulseVersionFilter}
                        setShouldPulse={setShouldPulseVersionFilter}
                    />
                </>
            );
        }
    };

    return (
        <div className="content-wrapper">
            <Header
                id={entry.header.id}
                pageId={entry.id}
                resources={entry.resources}
                additionalLinks={entry.header.additionalLinks}
                atlassianLogo={entry.header.atlassianLogo}
                logo={entry.header.atlassianSupportLogo.url}
                showPreviewBanner={entry.preview}
                atlassianSupportLogo={entry.header.atlassianSupportLogo}
                serverProducts={entry.header.serverProducts}
                cloudProducts={entry.header.cloudProducts}
            />
            <main>
                <h1> REDUX THINGS</h1>
                <div data-testid="search-results-page">
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleSubmit}
                                placeholder={
                                    translatableText.searchPlaceholder.text
                                }
                                initialValue={searchTerm}
                                analyticsMetadata={{
                                    parentComponent: 'search-results'
                                }}
                                onChange={handleSearchChange}
                            />
                        }
                    />
                    <div className="filters-container">
                        <FilterSelector
                            filterTitle="Deployment"
                            filterValues={deployments}
                            setQueryValues={setDeployment}
                            allowFilterAll={false}
                            queryByString={true}
                            defaultSelected={deployments[0].name}
                        />
                        <FilterSelector
                            filterTitle="Product"
                            filterValues={listOfProducts}
                            setQueryValues={setProductsQueryValue}
                            titleReset={resetProductTitle}
                            setTitleReset={setResetProductTitle}
                            shouldPulse={shouldPulseProductFilter}
                            setShouldPulse={setShouldPulseProductFilter}
                        />
                        {renderVersionFilter()}
                        <FilterSelector
                            filterTitle="Resource Type"
                            filterValues={contentArray}
                            setQueryValues={(value: Content[]) =>
                                dispatch(setContentFilterQueryValue(value))
                            }
                            allowFilterAll={true}
                        />
                        <ResetButton
                            onReset={() => {
                                setDeployment(Deployment.CLOUD);
                                setProductsQueryValue([]);
                                setListOfProducts(
                                    getProductFiltersByDeployment(
                                        entry?.filters?.products,
                                        Deployment.CLOUD
                                    )
                                );

                                setVersionQueryValue([]);
                                setListOfVersions([]);
                                dispatch(resetAllValues());
                            }}
                        />
                    </div>
                    <SearchResultsDisplay>
                        {isLoading ? (
                            <div className="search-results-spinner-container">
                                <Spinner size={'xlarge'} />
                            </div>
                        ) : searchResults?.queryId &&
                          searchResults.items?.length ? (
                            searchResults.items.map(
                                (result: any, index: number) => (
                                    <SearchResultCard
                                        key={result.id}
                                        id={result.id}
                                        title={
                                            isSearchTermHighlightingEnabled
                                                ? result?.highlights?.title ||
                                                  result.title
                                                : result.title
                                        }
                                        description={
                                            isSearchTermHighlightingEnabled
                                                ? result?.highlights
                                                      ?.description ||
                                                  result.description
                                                : result.description
                                        }
                                        href={result.url}
                                        dateModified={result.updatedDate}
                                        markedUseful={result.likes}
                                        views={result.views}
                                        searchTerm={searchTerm}
                                        position={index + 1}
                                        queryId={searchResults.queryId}
                                        products={result.products}
                                        deployment={deployment}
                                        isSearchTermHighlightingEnabled={
                                            isSearchTermHighlightingEnabled
                                        }
                                    />
                                )
                            )
                        ) : (
                            <NoSearchResults searchTerm={searchTerm} />
                        )}
                    </SearchResultsDisplay>
                </div>
            </main>
            <Footer
                logo={entry.header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};
