import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    sentryCaptureEvent,
    sentryCaptureException
} from '../../../../../utils/sentry/sentry-client';

import { Deployment, Content } from '../../../types';

export interface SuccessfulSearchResponse {
    queryId: string;
    items: SearchArticle[];
}

interface FailedSearchResponse {
    timestamp: number;
    status: number;
    error: string;
    path: string;
}

interface SearchArticle {
    url: string;
    id: string;
    title: string;
    description: string;
    updatedDate: string;
    likes: number;
    views: number;
    version?: string;
    products: {
        platform: string[];
    }[];
}

export interface SearchQuery {
    deployment?: Deployment;
    productsQueryValue?: string[];
    contentTypes?: Content[];
    version?: string[];
}

export const fetchSearchResults = createAsyncThunk(
    'search/fetchSearchResults',
    async ({
        searchTerm,
        query
    }: {
        searchTerm: string;
        query: SearchQuery;
    }) => {
        const HA_OVERRIDE_DOMAINS = new Set([
            'localhost',
            'support.dev.internal.atlassian.com',
            'support.uat.internal.atlassian.com'
        ]);

        const helpAggregatorUrl = HA_OVERRIDE_DOMAINS.has(
            window.location.hostname
        )
            ? 'https://support.stg.internal.atlassian.com/gateway/api/help-content/content/search'
            : '/gateway/api/help-content/content/search';

        try {
            const response = await fetch(helpAggregatorUrl, {
                body: JSON.stringify({
                    query: searchTerm,
                    highlightSearchTerms: true,
                    filters: {
                        platform: query.deployment,
                        // TODO: since not all right products, default (all) has limited results
                        product: query.productsQueryValue,
                        contentTypes: query.contentTypes,
                        version: query.version
                    }
                }),
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    'Sec-Fetch-Mode': 'cors',
                    'Sec-Fetch-Site': 'same-origin',
                    authority: 'support.dev.internal.atlassian.com'
                },
                method: 'POST'
            });

            const data = await response.json();
            if (!response.ok) {
                const { error } = data as FailedSearchResponse;
                sentryCaptureEvent({
                    event: {
                        message: error,
                        level: 'error',
                        contexts: {
                            response: {
                                status_code: response.status
                            }
                        },
                        extra: {
                            data
                        }
                    }
                });
            }
            return data;
        } catch (error) {
            sentryCaptureException({
                exception: error
            });
        }
    }
);
