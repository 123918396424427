import { useState, useEffect } from 'react';
import * as FeatureFlagFns from '../../feature-flags';

interface Options {
    featureFlagFnName: string;
    defaultValue: boolean;
    productName?: string;
}

type FeatureFlagFnWithProductSlug = (productSlug: string) => Promise<boolean>;
type FeatureFlagFnWithNoParameters = (noParam?: never) => Promise<boolean>;

interface FeatureFlagFunctions {
    [key: string]: FeatureFlagFnWithProductSlug | FeatureFlagFnWithNoParameters;
}

function needsParameter(
    featureFlagFn: FeatureFlagFnWithProductSlug | FeatureFlagFnWithNoParameters,
    productName: string | undefined
): featureFlagFn is FeatureFlagFnWithProductSlug {
    return (
        (featureFlagFn as FeatureFlagFnWithProductSlug).name ===
            'isLocalizationTagsEnabled' &&
        !!productName &&
        typeof productName === 'string'
    );
}

export const useIsFeatureEnabled = ({
    featureFlagFnName,
    defaultValue,
    productName
}: Options) => {
    const [isFeatureEnabled, setIsFeatureEnabled] = useState(defaultValue);

    async function updateFeatureFlagState() {
        const featureFlagFn = (FeatureFlagFns as FeatureFlagFunctions)[
            featureFlagFnName
        ];
        const isEnabled = needsParameter(featureFlagFn, productName)
            ? await featureFlagFn(productName as string) // we can infer productName is a string by typeguard
            : await featureFlagFn();

        setIsFeatureEnabled(isEnabled);
    }

    useEffect(() => {
        updateFeatureFlagState();
        /* eslint-disable-next-line react-hooks/exhaustive-deps*/
    }, []);

    return isFeatureEnabled;
};
