import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    fetchSearchResults,
    SuccessfulSearchResponse
} from './utils/fetch-search-results';

export interface SearchState {
    isLoading: boolean;
    searchTerm: string;
    searchResults: SuccessfulSearchResponse;
}

const initialState: SearchState = {
    isLoading: false,
    searchTerm: '',
    searchResults: { queryId: '', items: [] }
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResults.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSearchResults.fulfilled, (state, action) => {
                state.isLoading = false;
                state.searchResults = action.payload;
            })
            .addCase(fetchSearchResults.rejected, (state) => {
                state.isLoading = false;
            });
    }
});

export const { updateSearchTerm } = searchSlice.actions;

export default searchSlice.reducer;
