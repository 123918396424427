import React from 'react';
import { AtlasLink } from '../atlas-link';
import './product-card.less';
import analytics from '../../utils/analytics';

interface ProductCardProps {
    title: string;
    icon: string;
    url: string;
    id?: string;
}

export const ProductCard = ({ title, icon, url, id }: ProductCardProps) => {
    return (
        <AtlasLink
            href={url}
            className="products__link product-card"
            data-testid={`product-card-${id}`}
            onClick={() => {
                analytics.trackEvent('product card', {
                    // @ts-ignore
                    event: 'clicked',
                    eventComponent: 'product-card',
                    actionSubjectId: 'link',
                    category: 'product',
                    action: 'clicked',
                    label: 'product card',
                    product: title,
                    productUrl: url
                });
            }}
        >
            <img
                src={icon}
                alt=""
                height="95"
                width="95"
                className="icon-image"
                data-testid={`product-img-${id}`}
            />
            <p className="product-title-text">{title}</p>
        </AtlasLink>
    );
};
