import React, { useState, useEffect } from 'react';
// @ts-ignore
import { AtlassianLogo } from '@atlaskit/logo';
import './easter-egg.less';

const creditsData = [
    {
        heading: 'Galactica Development Team',
        items: [
            {
                name: 'Kent Gillenwater',
                title: 'Developer Mananger (Former)'
            },
            {
                name: 'Joe Clark',
                title: 'Project Owner (Former)'
            },
            {
                name: 'John Collins (JoCo)',
                title: 'Senior Content Designer'
            },
            {
                name: 'Dustin Martin (DMoney)',
                title: 'Tech Team Lead'
            },
            {
                name: 'John Flournoy (JoFo)',
                title: 'Software Engineer'
            },
            {
                name: 'Edgar Gomez',
                title: 'Software Engineer'
            },
            {
                name: 'Jennifer Lansing-Saenz',
                title: 'Developer Mananger'
            },
            {
                name: 'Gregg Wehmeier',
                title: 'Software Engineer'
            },
            {
                name: 'Grant Redfearn',
                title: 'Software Engineer (Former)'
            },
            {
                name: 'Mike Fleming',
                title: 'Software Engineer'
            },
            {
                name: 'Ben Vera',
                title: 'Software Engineer'
            },
            {
                name: 'Ceci Goss',
                title: 'Software Engineer (Cool™)'
            },
            {
                name: 'Phillip Shim',
                title: 'Software Engineer'
            },
            {
                name: 'Christian Tschoepe',
                title: 'Software Engineer'
            },
            {
                name: 'Matt Yee',
                title: 'Software Engineer'
            },
            {
                name: 'Michael Thompson',
                title: 'Software Engineer'
            },
            {
                name: 'Elias Zeidan',
                title: 'Software Engineer'
            },
            {
                name: 'Angela Patini',
                title: 'Software Engineer'
            },
            {
                name: 'Michelle Kerri',
                title: 'Software Engineer'
            },
            {
                name: 'John Schulz',
                title: 'Software Engineer'
            },
            {
                name: 'Ammie Wang',
                title: 'Software Engineer Intern'
            },
            {
                name: 'Carolina Barboza',
                title: 'Software Engineer Intern'
            },
            {
                name: 'Vikas Bansal',
                title: 'Software Engineer'
            },
            {
                name: 'Hariharan K',
                title: 'Software Engineer'
            },
            {
                name: 'Krishan Patel',
                title: 'Software Engineer'
            },
            {
                name: 'Herbert Gutierrez',
                title: 'Software Engineer'
            },
            {
                name: 'Latrell Lee',
                title: 'Software Engineer'
            },
            {
                name: 'Izana Melese',
                title: 'Software Engineer Intern'
            },
            {
                name: 'Saahiti Vankayalapati',
                title: 'Software Engineer Intern'
            },
            {
                name: 'Miguel Sarenas',
                title: 'Software Engineer'
            },
            {
                name: 'Youssef Serag',
                title: 'SWE Intern Summer 2024'
            }
        ]
    }
];

export const EasterEgg = () => {
    const [showCredits, setShowCredits] = useState(false);
    const [showPewPew, setShowPewPew] = useState(false);
    const [atMaxOpacity, setAtMaxOpacity] = useState(false); // Use for fade-in

    const startCreditsAnimation = () => {
        setShowCredits(true);
        setTimeout(() => setAtMaxOpacity(true), 500);
    };

    const endCreditsAnimation = () => {
        setAtMaxOpacity(false);
        setTimeout(() => setShowCredits(false), 2500);
    };

    useEffect(() => {
        // @ts-ignore
        window.pewpew = () => {
            setShowPewPew(true);
            setTimeout(() => {
                setShowPewPew(false);
                startCreditsAnimation();
                setTimeout(() => endCreditsAnimation(), 15000);
            }, 3000);
        };
    }, []);

    if (!showCredits && !showPewPew) {
        return null;
    }
    return showCredits ? (
        <div
            className={[
                'credits-container',
                atMaxOpacity ? '' : 'credits-container--hidden'
            ].join(' ')}
        >
            <div className="credits">
                {creditsData.map(({ heading, items }, index) => (
                    <div className="credits__section" key={index}>
                        <h3 className="credits__heading">{heading}</h3>
                        {items.map(({ name, title }, index) => (
                            <React.Fragment key={index}>
                                <div className="credits__row">
                                    <div className="credits__item credits__item--left">
                                        {name}
                                    </div>
                                    <div className="credits__item credits__item--right">
                                        {title}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                ))}
                <div className="credits__section">
                    <h3 className="credits__heading">Special thanks to...</h3>
                    <div className="credits__item">John</div>
                    <div className="credits__item">QA Blitz playlist</div>
                    <div className="credits__item">JoCo&#39;s dad jokes</div>
                    <div className="credits__item">
                        CAC giving us nightmares
                    </div>
                    <div className="credits__item">The Interstitial Modal</div>
                    <div className="credits__item">
                        width: 65.43859649122807%
                    </div>
                </div>
                <AtlassianLogo />
            </div>
        </div>
    ) : (
        <img
            className="pew-pew"
            alt="space ship"
            src="https://images.ctfassets.net/zsv3d0ugroxu/6VfRJlYiwhAF32YxRqiNUA/ba7779c0e2d16e69f2563f3629640e68/space-ship-art.png?h=250"
        />
    );
};
