import React from 'react';
import './search-result-card.less';

export const SearchResultsDisplay = ({
    children
}: {
    children: React.ReactNode;
}) => {
    return (
        <div className="search-result-card__result-container">{children}</div>
    );
};
