/* eslint-disable camelcase */
import { formatEventName, formatEventProperties } from './helpers';
import { getAnalyticsObject, setAnalyticsRefs } from './set-window';
import { trackEvent } from './track-event';
import { Target } from './types';

declare global {
    interface Window {
        ace: any;
        __APP_INITIAL_STATE__: any;
    }
}

// Initializes analytics package
const init = (key: string) => {
    // set window object and storage objects
    setAnalyticsRefs();
    const analytics = getAnalyticsObject();
    if (analytics && !analytics.alreadyInvoked) {
        // try to get segemnt key from window, will be null at first because it's rendered on the server first
        if (key) {
            // contentful_entry_id will always be null if any of window.__APP_INITIAL_STATE__.entry.id
            //  is not defined
            analytics.Initializer.initWithPageAnalytics(key, {
                contentful_entry_id: window?.__APP_INITIAL_STATE__?.entry?.id,
                featured_entries:
                    window?.__APP_INITIAL_STATE__?.featuredEntries,
                gasv3Product: 'sac'
            });
            analytics.alreadyInvoked = true;
        }
    }
};

// Processes analytics events
const processEvent = (target: Target, action: string, metaData: object) => {
    const eventProperties = formatEventProperties(target, metaData);
    const eventName = formatEventName(target, action);

    if (typeof window.ace !== 'undefined') {
        eventProperties.eventComponent = target;
        eventProperties.event = action;
        eventProperties._sio = window.ace.mkt.Cookie.readCookie('_sio') || '';
        eventProperties._atl_path =
            window.ace.mkt.Cookie.readCookie('__atl_path') || '';
        eventProperties.contentful_entry_id =
            window?.__APP_INITIAL_STATE__?.entry?.id;
    }

    return trackEvent(eventName, eventProperties);
};

const trackLinkClick = (href: string | null, linkText?: string) => {
    if (href) {
        processEvent('LinkClicked', '', {
            target_link: href,
            link_text: linkText ? linkText : ''
        });
    }
};

const getAnonymousId = () => {
    const analytics = getAnalyticsObject();

    if (analytics) {
        return analytics.SegmentIO.getAnonymousId();
    }
};

export default {
    init,
    trackLinkClick,
    processEvent,
    trackEvent,
    getAnonymousId
};
