/* eslint-disable react/prop-types */
import React from 'react';
// @ts-ignore
import { TopicReference } from 'src/renderer/components/sidebar/sidebar.types';
// @ts-ignore
import AuthorTools from '../author-tools';

interface TopicProps {
    topics: TopicReference[];
}

const Topics: React.FC<TopicProps> = ({ topics }) => {
    return (
        <>
            {topics &&
                topics[0] &&
                topics.map((topic) => {
                    const classes = ['topic__body'];
                    if (topic.sneakyUrl) {
                        classes.push('adf__content');
                    }

                    return (
                        <div
                            key={topic.id}
                            className={classes.join(' ')}
                            id={topic.id}
                        >
                            <AuthorTools
                                pageId={topic.id}
                                tooltipContent="Edit this topic"
                                parentAligned={true}
                                confluencePageId={topic.confluencePageId}
                            />

                            {topic.sneakyUrl && (
                                <h2
                                    style={{
                                        marginBottom: '12px'
                                    }}
                                >
                                    {topic.title}
                                </h2>
                            )}
                            {topic.body && (
                                <div
                                    key={topic.id}
                                    dangerouslySetInnerHTML={{
                                        __html: topic.body
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
        </>
    );
};

export default Topics;
