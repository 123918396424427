import React from 'react';

import { HeroBanner } from '../../components/hero-banner';
import { Search, handleOnSearch } from '../../components/search';
import { Header } from '../../components/header';
import Footer from '../../components/footer';
import {
    Card,
    CardGroup,
    CardsWithTitleAdjustment
} from '../../components/card';
import { FeaturedContent } from '../../components/featured-content';
import { ContactCtaBlock } from '../../components/contact';
import EasterEgg from '../../components/easter-egg';
import { HomePageType } from './home.type';
import './home.less';
import { translatableText } from '../../utils/translatable-text';
import { DeploymentSelectorTabs } from '../../components/deployment-selector-tabs';

const SACHomePage = (props) => {
    const {
        entry: {
            cards,
            featuredResources,
            featuredContent,
            header,
            cloudProducts,
            serverProducts,
            id,
            preview
        }
    } = props;

    const renderCards = (cards) => {
        const filteredCards = cards.filter((item) => !item.hideInHeader);
        return (
            <CardsWithTitleAdjustment collection={filteredCards}>
                {({ item, measureRef, titleHeight }) => (
                    <Card
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        buttonText={item.buttonText}
                        buttonLink={item.buttonLink}
                        analytics={item.analytics}
                        icon={item.icon ? item.icon.url : ''}
                        description={item.sacHomeDescription || ''}
                        measureRef={measureRef}
                        titleHeight={titleHeight}
                    />
                )}
            </CardsWithTitleAdjustment>
        );
    };

    return (
        <div className="home" data-testid="page-template">
            <div className="content-wrapper">
                <Header
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    logo={header.atlassianSupportLogo.url}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    id={header.id}
                    additionalLinks={header.additionalLinks}
                    resources={featuredResources}
                    showPreviewBanner={preview}
                    pageId={id}
                />
                <main>
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleOnSearch}
                                placeholder="Tell us what you're looking for..."
                                analyticsMetadata={{
                                    parentComponent: 'hero-banner'
                                }}
                            />
                        }
                    />

                    <div className="main-content-container">
                        {featuredContent?.featuredEntries?.length && (
                            <div
                                className="home__featured-content"
                                data-testid="home-featured-content"
                            >
                                <div className="home__featured-content--inner">
                                    <FeaturedContent
                                        content={featuredContent}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="home__browse margin-top-medium">
                            <div className="home__browse--inner">
                                <h2 className="home__header">
                                    {translatableText.browseByProduct.text}
                                </h2>
                                <DeploymentSelectorTabs
                                    cloudProducts={cloudProducts}
                                    serverProducts={serverProducts}
                                    details={'home page'}
                                />
                            </div>
                        </div>
                        <div className="home__browse margin-top-medium">
                            <div className="home__browse--inner">
                                <h2 className="home__header">
                                    {translatableText.browseResources.text}
                                </h2>
                                <CardGroup>
                                    {renderCards(featuredResources.cards)}
                                    {renderCards(cards)}
                                </CardGroup>
                            </div>
                        </div>
                        <ContactCtaBlock />
                    </div>
                </main>
            </div>
            <Footer
                logo={header.atlassianLogo.url}
                className={'margin-top-large'}
            />
            <EasterEgg />
        </div>
    );
};

SACHomePage.defaultProps = {};

SACHomePage.propTypes = {
    entry: HomePageType
};

export default SACHomePage;
