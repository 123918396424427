import React from 'react';
import { AtlasLink } from '../atlas-link';
import kebabCase from 'lodash/kebabCase';
// @ts-ignore
import { translatableText } from '../../utils/translatable-text';
import {
    CONTEXT_APP,
    CONTEXT_PRODUCT,
    CONTEXT_THEME
} from '../../../contentful/content-types';

interface Props {
    productName: string | null;
    contextType: string;
}

// eslint-disable-next-line complexity
const Community = (props: Props) => {
    const { productName, contextType } = props;
    let url;

    if (contextType === CONTEXT_APP) {
        // TODO: Come up with long-term solution to plug in appropriate app community links.
        // Ideally we wouldn't hardcode in the Jira Mobile app community URL here and instead have this information stored in Contentful.
        // See FRAK-1767.
        if (productName?.toLowerCase().includes('jira cloud app')) {
            url =
                'https://community.atlassian.com/t5/Jira-Mobile-and-Desktop-Apps/ct-p/jira-mobile-apps';
        } else {
            url = 'https://community.atlassian.com/t5/Products/ct-p/products';
        }
    }

    if (contextType === CONTEXT_THEME) {
        // TODO: Come up with long-term solution to plug in appropriate theme community links.
        // See FRAK-1767.
        url = 'https://community.atlassian.com/t5/Interests/ct-p/interests';
    }

    if (contextType === CONTEXT_PRODUCT) {
        if (productName) {
            if (productName === 'Jira Service Management') {
                url = `https://community.atlassian.com/t5/${kebabCase(
                    productName
                )}/ct-p/jira-service-desk`;
            } else if (
                productName === 'Jira Product Discovery (beta)' ||
                productName === 'Jira Product Discovery'
            ) {
                url =
                    'https://community.atlassian.com/t5/Jira-Product-Discovery/gh-p/jira-product-discovery';
            } else if (
                productName === 'Atlassian Analytics (beta)' ||
                productName === 'Atlassian Analytics'
            ) {
                url =
                    'https://community.atlassian.com/t5/Atlassian-Analytics/ct-p/analytics';
            } else {
                url = `https://community.atlassian.com/t5/${kebabCase(
                    productName
                )}/ct-p/${kebabCase(productName)}`;
            }
        } else {
            url = 'https://community.atlassian.com/t5/Products/ct-p/products';
        }
    }

    if (!url) {
        url = 'https://community.atlassian.com';
    }

    return (
        <div data-testid="community-sidebar-nav" className="sidebar__section">
            <span className="sidebar__heading">
                {translatableText.community.text}
            </span>
            <AtlasLink
                className="sidebar__link"
                text={translatableText.questionsDiscussionsAndArticles.text}
                href={url}
                dataName="communitySidebarLink"
            />
        </div>
    );
};

Community.defaultProps = {
    productName: null
};

export default Community;
