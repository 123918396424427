import {
    DOCUMENTED_PRODUCT,
    PRODUCT_LANDING_PAGE
} from '../contentful/content-types';
import { DOCUMENTATION, NOT_APPLICABLE, CLOUD } from './constants';
import slugify from 'slugify';
import isString from 'lodash/isString';
import { LOGIN_ATLASSIAN, APPLICATION_NAME } from '../renderer/utils/constants';

interface URLStructureParams {
    productName: string;
    contentType: string;
    versionValue: string;
    deploymentValue: string;
    pageTypeValue?: string;
    urlField?: string | { 'en-US': string };
}

const replaceDoubleSlashes = (slug: string) => slug.replace(/\/\//g, '/');

const getVersionURLFragment = (deployment: string, version: string) => {
    // version is only added to url if version & deployment are set & deployment is not cloud
    // we may want to standardize the 'Not Applicable' case in contentful

    const regex = new RegExp(NOT_APPLICABLE, 'i');
    const includeVersion =
        !regex.test(version) || ![CLOUD, NOT_APPLICABLE].includes(deployment);

    return includeVersion ? `${version}/` : '';
};

export const buildURL = (args: URLStructureParams) => {
    const {
        productName,
        contentType,
        versionValue,
        deploymentValue,
        pageTypeValue
    } = args;

    // normalize urlField to be a string no matter what is passed
    // args.urlField can either be a string or { 'en-US': string }
    let urlField = '';
    if (args.urlField) {
        if (isString(args.urlField)) {
            urlField = args.urlField;
        } else if (args.urlField?.['en-US']) {
            urlField = args.urlField['en-US'];
        }
    }

    const baseURL = `/${productName}/`;

    if (contentType === PRODUCT_LANDING_PAGE) {
        return replaceDoubleSlashes(baseURL);
    }

    const baseURLWithVersion = baseURL.concat(
        getVersionURLFragment(deploymentValue, versionValue)
    );

    if (contentType === DOCUMENTED_PRODUCT) {
        return replaceDoubleSlashes(`${baseURLWithVersion}resources/`);
    }

    if (!pageTypeValue) {
        return replaceDoubleSlashes(baseURLWithVersion);
    }

    const convertedPageType =
        pageTypeValue === DOCUMENTATION
            ? 'docs'
            : pageTypeValue.replace(/\s+/g, '-');

    // strip any remaining special chars except hyphens and underscores to prevent
    // chars like single quotes, which are allowed through Contentful's slugs.
    const sanitizedUrlField = slugify(urlField, {
        remove: /[^\w -]/g
    });

    return replaceDoubleSlashes(
        `${baseURLWithVersion}${convertedPageType}/${sanitizedUrlField}/`
    );
};

export function addCurrentEvnToDomain(targetUrl: string, host: string): string {
    const url = new URL(targetUrl);
    const [sub, domain, extension] = url.host.split('.');
    if (host.includes('.stg.')) {
        url.host = `${sub}.stg.internal.${domain}.${extension}`;
    }
    if (host.includes('.dev.')) {
        url.host = `${sub}.dev.internal.${domain}.${extension}`;
    }
    if (host.includes('.uat.')) {
        url.host = `${sub}.uat.internal.${domain}.${extension}`;
    }

    return url.href;
}

// WHY? read this: https://developer.atlassian.com/platform/identity/learning/sso/integrate-with-account-switcher/#synchronize-accounts
export function wrapUrlWithLogin({
    url,
    host,
    email,
    prompt
}: {
    url: string;
    host: string;
    prompt: string;
    email?: string;
}): string {
    // login URL should be adjusted to include the current environment
    const loginUrlWithEnv = addCurrentEvnToDomain(LOGIN_ATLASSIAN, host);

    // add params to the login URL
    const resultURL = new URL(loginUrlWithEnv);
    resultURL.searchParams.set('prompt', prompt);
    resultURL.searchParams.set('application', APPLICATION_NAME);
    resultURL.searchParams.set('continue', encodeURI(url));
    if (email) {
        resultURL.searchParams.set('login_hint', encodeURI(email));
    }

    /** EXAMPLE:
    https://id.atlassian.com/login?application=bitbucket&continue=https%3A%2F%2Fid.atlassian.com%2Fmanage-profile&login_hint=foo%40bar.com&prompt=none.
     */
    return resultURL.href;
}
