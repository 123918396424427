import { getAnalyticsObject } from './set-window';
import { EventProperties } from './types';

// Uses the analytics variable to track events
export const trackEvent = (
    eventName: string,
    eventProperties: EventProperties
) => {
    const analytics = getAnalyticsObject();
    //Need to invoke segment.io directly. ATL Analytics do not give us the callback feature
    if (analytics) {
        analytics.Tracking.trackEvent(eventName, eventProperties);
    }
};
