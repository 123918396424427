import React from 'react';
import Button from '@atlaskit/button';

interface ResetButtonProps {
    onReset: () => void;
}

const ResetButton = ({ onReset }: ResetButtonProps) => {
    return (
        <Button appearance="subtle" onClick={onReset}>
            Clear filters
        </Button>
    );
};

export default ResetButton;
