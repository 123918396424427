import React, { useEffect, useState } from 'react';
import { Header, HeaderType } from '../../components/header';
import Footer from '../../components/footer';
import { arrayOf, bool, shape, string } from 'prop-types';
import { PageTreeType } from '../../utils/page-tree-type';
import { ResourceType } from '../../components/card';
import './contact.less';
import Breadcrumbs from '../../components/breadcrumbs';
import RemoteReactApp from '../../components/remote-react-app';
import { getExperiment } from '../../../statsig/statsig-client';
import * as ct from 'countries-and-timezones';

import {
    AuthApiAvailableStates,
    useAuth
} from '../../providers/auth-providers';
import {
    STATSIG_EXPERIMENTS,
    STATSIG_EXPERIMENTS_DEFAULTS
} from '../../../statsig/experiments';
import Spinner from '@atlaskit/spinner';

const ContactPage = (props) => {
    const { isAuthenticated, aaId, email, authApiTransitionState } = useAuth();
    const [showConciergePage, setShowConciergePage] = useState();

    useEffect(() => {
        if (
            isUserComingFromConcierge() ||
            authApiTransitionState === AuthApiAvailableStates.FAILURE
        ) {
            setShowConciergePage(false);
        } else if (
            isAuthenticated &&
            authApiTransitionState === AuthApiAvailableStates.SUCCESS
        ) {
            (async function () {
                await evaluateExperimentAndSetState(
                    aaId,
                    email,
                    setShowConciergePage
                );
            })();
        }
    }, [isAuthenticated, aaId, email, authApiTransitionState]);

    const {
        entry: { resourceCards, header, id, preview, context },
        pageTree
    } = props;

    return (
        <div className="contact flex-column-page" data-testid="page-template">
            <div className="content-wrapper">
                <Header
                    resources={resourceCards}
                    logo={header.atlassianSupportLogo}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    id={header.id}
                    showPreviewBanner={preview}
                    additionalLinks={header.additionalLinks}
                    pageId={id}
                />
                <main className="main-content-container margin-top-large">
                    {showConciergePage === false && (
                        <div className="contact__sub-header">
                            <Breadcrumbs
                                pageTree={pageTree}
                                entryId={id}
                                context={context}
                            />
                        </div>
                    )}
                    <div className="contact__forms" data-testid="contact-forms">
                        {showConciergePage !== undefined
                            ? showConciergePage === true
                                ? renderASHA()
                                : renderSACForms()
                            : [
                                  AuthApiAvailableStates.NOT_INITIATED,
                                  AuthApiAvailableStates.LOADING
                              ].includes(authApiTransitionState) &&
                              renderContactSpinner()}
                    </div>
                </main>
            </div>
            <Footer
                logo={header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};

ContactPage.propTypes = {
    entry: shape({
        resourceCards: shape({
            cards: arrayOf(ResourceType)
        }),
        header: HeaderType,
        id: string.isRequired,
        preview: bool
    }),
    pageTree: PageTreeType
};

const renderSACForms = () => {
    const manifestUrl = () =>
        `${window.CONTACT_FORMS_MANIFEST}?v=${new Date().getTime()}`;

    return (
        <RemoteReactApp
            appTargetId="support-form-app"
            manifestUrlProvider={manifestUrl}
            filesFilter={['index.js', 'index.css']}
            includeLoadingSpinner={true}
            executeAfterMount={() => {
                window.SUPPORT_FORM_APP = {
                    IS_PARTNER_SUPPORT_TYPE: false
                };
            }}
        />
    );
};

const renderASHA = () => {
    // Unique param enforces browser to skip cached resource.
    // This will be fixed with parcel cache ttl, but this fix is for existing cached resources
    const millis = Date.now();
    return (
        <RemoteReactApp
            appTargetId="support-help-app"
            manifestUrlProvider={() =>
                window.ASHA_APP_MANIFEST + '?v=' + millis
            }
            filesFilter={['index.html']}
            includeLoadingSpinner={true}
        />
    );
};

const renderContactSpinner = () => {
    return (
        <div className="contact_spinner" data-testid="contact_spinner_id">
            <Spinner size={'xlarge'} />
        </div>
    );
};

const evaluateExperimentAndSetState = async (
    aaId,
    email,
    setShowConciergePage
) => {
    const identifiers = {
        atlassianAccountId: aaId
    };

    const customAttributes = {
        referrer: (document.referrer?.split('/')[2] || '').toString(),
        userEmailDomain: getUserEmailDomain(email).toString(),
        ...getLocationInfo()
    };

    const conciergeExperiment = await getExperiment(
        STATSIG_EXPERIMENTS.CONCIERGE_V1_EXPERIMENT,
        identifiers,
        customAttributes
    );

    const conciergeViewEnabled = conciergeExperiment.getValue(
        'ConciergeViewEnabled',
        STATSIG_EXPERIMENTS_DEFAULTS[
            STATSIG_EXPERIMENTS.CONCIERGE_V1_EXPERIMENT
        ].ConciergeViewEnabled
    );

    setShowConciergePage(conciergeViewEnabled);
};

const isUserComingFromConcierge = () => {
    const supportType =
        window?.location?.href && getSupportTypeInURL(window.location.href);
    if (supportType) {
        return true;
    }

    if (document.referrer === '') {
        return false;
    }

    // Get the domain and path of the current page
    const currentPage = window.location.hostname + window.location.pathname;

    // Get the domain and path of the referrer (the last page)
    const referrerUrl = new URL(document.referrer);
    const referrer = referrerUrl.hostname + referrerUrl.pathname;

    return referrer === currentPage;
};

const getLocationInfo = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
    const countryCode = ct.getCountryForTimezone(timezone)?.id || '';

    return {
        countryCode: countryCode,
        timezone: getTimeZone()
    };
};

const getTimeZone = () => {
    const now = new Date();
    const tzOffset = now.getTimezoneOffset();
    let gmtOffset = Math.round(tzOffset / -60);
    // eslint-disable-next-line eqeqeq
    if (gmtOffset == '-0') {
        gmtOffset = 0;
    }
    return `GMT${gmtOffset >= 0 ? '+' : ''}${gmtOffset}`;
};

const getUserEmailDomain = (email) => {
    if (email && email.includes('@')) {
        return email.trim().split('@')[1];
    }

    return '';
};

const getSupportTypeInURL = (pageURL) => {
    const url = new URL(pageURL);
    let hashParams = new URLSearchParams(url.hash.split('?')[1]);
    if (hashParams.size === 0) {
        return null;
    }

    let supportType = hashParams.get('support_type');

    return supportType;
};
export default ContactPage;
